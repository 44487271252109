import { useMetaplex, useWorkspace } from '@/composables';
import { PublicKey } from '@solana/web3.js';
import { utils, BorshAccountsCoder } from "@coral-xyz/anchor";
import { fetchIdlAccountDataById } from "@cardinal/common";
import { ellipsisText } from '@/composables';
import { useCommonStore } from '@/store';
const anchor = require('@project-serum/anchor');

import dotenv from 'dotenv';
dotenv.config();

const REWARDS_CENTER_ADDRESS = new PublicKey(process.env.VUE_APP_REWARDS_CENTER_ADDRESS);

export const nftReleaseNum = async () => {

    const metaplex = useMetaplex();
    const owner = new PublicKey('9R3cL5o8DjkrKHpjmD99YxY7x5UagoZ7RzG1FNFHNLkK');

    const nfts = await metaplex.nfts().findAllByOwner({ owner: owner });

    return 100 - nfts.length;

}

export const poolNum = async () => {

    const { wallet } = useWorkspace();

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl)
    const provider = new anchor.AnchorProvider(connection, wallet)
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const programAccounts = await connection.getProgramAccounts(
        REWARDS_CENTER_ADDRESS,
        {
            filters: [
                {
                    memcmp: {
                        offset: 0,
                        bytes: utils.bytes.bs58.encode(
                            BorshAccountsCoder.accountDiscriminator('StakePool')
                        ),
                    },
                },
            ],
        }
    );

    const stakePoolDatas = [];
    console.log(stakePoolDatas)
    const coder = new BorshAccountsCoder(idl);
    programAccounts.forEach((account) => {
        try {
            const stakePoolData = coder.decode('StakePool', account.account.data);
            if (stakePoolData) {
                stakePoolDatas.push({
                    ...account,
                    parsed: stakePoolData,
                    config: null,//先默認一個鍵，在接下來獲取config之後push進去
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-empty
        }
    });

    return stakePoolDatas.length;

}

export const Pool = async () => {

    const { wallet } = useWorkspace();

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl)
    const provider = new anchor.AnchorProvider(connection, wallet)
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const poolList = []

    const programAccounts = await connection.getProgramAccounts(
        REWARDS_CENTER_ADDRESS,
        {
            filters: [
                {
                    memcmp: {
                        offset: 0,
                        bytes: utils.bytes.bs58.encode(
                            BorshAccountsCoder.accountDiscriminator('StakePool')
                        ),
                    },
                },
            ],
        }
    );

    if (programAccounts.length > 0) {

        const stakePoolDatas = [];

        const coder = new BorshAccountsCoder(idl);
        programAccounts.forEach((account) => {
            try {
                const stakePoolData = coder.decode('StakePool', account.account.data);
                if (stakePoolData) {
                    stakePoolDatas.push({
                        ...account,
                        parsed: stakePoolData,
                        config: null,//先默認一個鍵，在接下來獲取config之後push進去
                    });
                }
            } catch (e) {
                // eslint-disable-next-line no-empty
            }
        });

        for (const pool of stakePoolDatas) {

            if (pool.parsed.authority.toString() == provider.wallet.publicKey.value.toString()) {

                const keyBuffer = Buffer.from(pool.parsed.identifier, 'utf-8')
                const prefixBuffer = Buffer.from('ss', 'utf-8')

                const configEntryId = PublicKey.findProgramAddressSync(
                    [
                        utils.bytes.utf8.encode("config-entry"),
                        prefixBuffer,
                        keyBuffer
                    ],
                    REWARDS_CENTER_ADDRESS
                )[0];

                const configdata = await fetchIdlAccountDataById(
                    connection,
                    [configEntryId],
                    REWARDS_CENTER_ADDRESS,
                    idl
                )

                if (configdata[Object.keys(configdata)[0]]) {

                    // 這裡是stringn所以用正規表達式拆解所有dict然後獲取最後一次更新內容 
                    const latest_config_data = configdata[Object.keys(configdata)[0]].parsed.value.match(/\{[^}]*\}/g);
                    pool.config = JSON.parse(latest_config_data[latest_config_data.length - 1] + "}")

                    if (pool.config.displayName == "") {
                        pool.config.displayName = ellipsisText(pool.pubkey.toBase58());
                    }

                    if (pool.config.name == "") {
                        pool.config.name = pool.pubkey.toBase58();
                    }

                } else {

                    // 如果還沒有初始化過會出現parsed錯誤，所以需要給一個默認config
                    const displayName = pool.pubkey.toBase58();
                    pool.config = {
                        name: displayName,
                        displayName: ellipsisText(displayName),
                        description: "",
                        imageUrl: "",
                        stakePoolAddress: "",
                    }

                }

                poolList.push(pool)
            }
        }

    }

    return poolList;
}

export async function getStakePoolsByAuthority(connection, provider) {

    let idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const programAccounts = await connection.getProgramAccounts(
        REWARDS_CENTER_ADDRESS,
        {
            filters: [
                {
                    memcmp: {
                        offset: 0,
                        bytes: utils.bytes.bs58.encode(
                            BorshAccountsCoder.accountDiscriminator('StakePool')
                        ),
                    },
                },
            ],
        }
    );

    const stakePoolDatas = [];

    if (programAccounts.length > 0) {

        const coder = new BorshAccountsCoder(idl);

        programAccounts.forEach((account) => {
            try {
                const stakePoolData = coder.decode('StakePool', account.account.data);
                if (stakePoolData) {
                    stakePoolDatas.push({
                        ...account,
                        parsed: stakePoolData,
                    });
                }
            } catch (e) {
                // eslint-disable-next-line no-empty
            }
        });

    }

    return stakePoolDatas
}