import { useWorkspace, full_config } from '@/composables';
import {
    Transaction,
    PublicKey,
    SystemProgram
} from '@solana/web3.js';
import { chunkArray, executeTransaction } from "@cardinal/common"; //withFindOrInitAssociatedTokenAccount
import { getOrCreateAssociatedTokenAccount, getMint, NATIVE_MINT, getAssociatedTokenAddressSync } from "@solana/spl-token";
import { getStakePoolsByAuthority } from './NonCustodialView'
import { useCommonStore } from '@/store';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js"
import { utils } from "@coral-xyz/anchor";
import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

const anchor = require('@project-serum/anchor');

const REWARDS_CENTER_ADDRESS = new PublicKey(process.env.VUE_APP_REWARDS_CENTER_ADDRESS);
const METADATA_PROGRAM = new PublicKey(process.env.VUE_APP_METADATA_PROGRAM);

export const createStakingPool = async (
    nftCollectionAddresses, rewardsInfo,
    featuresInfo, configInfo, collectionDistributor,
    attributeDistributor, promotionCode, payTokenAddress, 
    activeStep
) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;
    wallet.signTransaction = wallet.signTransaction.value ?? wallet.signTransaction;

    // Features Info
    const minimum_stake_duration = featuresInfo.minimum_stake_duration;
    const cooldown_period = featuresInfo.cooldown_period;
    const reset_on_stake = featuresInfo.reset_on_stake;
    let end_date = featuresInfo.end_date;
    if (end_date != null) {
        end_date = new anchor.BN((new Date(end_date)).getTime() / 1000);
    }

    // Pool Info
    const collections = [];

    for (let i = 0; i < nftCollectionAddresses.length; i++) {
        try {
            const newAddress = new PublicKey(nftCollectionAddresses[i].address);
            collections.push(newAddress);
        } catch {
            ElMessage.error('Invalid collection address');
            activeStep.value = 1;
            return;
        }
    }

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;

    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];
    const connection = new anchor.web3.Connection(clusterUrl)
    const provider = new anchor.AnchorProvider(connection, wallet)

    const allpools = await getStakePoolsByAuthority(connection, provider)
    const stakePoolIdentifier = `ml-np-${allpools.length + 1}`;

    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);
    const program = new anchor.Program(idl, REWARDS_CENTER_ADDRESS, provider);

    const stakePoolId = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode('stake-pool'),
            anchor.utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    // Check config name first
    // 因為資料是合併成字串的關係，無法限制輸入的name在program是唯一
    const configList = await full_config(idl, connection);

    for (const config of configList) {

        const config_parsed = config.parsed.value.match(/\{[^}]*\}/g)

        if (config_parsed) {

            try {

                const latest_config = JSON.parse(config_parsed[config_parsed.length - 1] + "}");

                if (latest_config.name == configInfo.name) {
                    ElMessage.error('The url name already exists.');
                    activeStep.value = 0;
                    return;
                }

            } catch (err) {
                console.log(err);
            }

        }

    }

    const metaplex = new Metaplex(connection);
    metaplex.use(walletAdapterIdentity(wallet));

    // 判斷錢包內所有nft和特定collection
    const nfts = await metaplex.nfts().findAllByOwner({ owner: wallet.publicKey });

    let holder_nft = [];

    for (const nft of nfts) {
        if (nft.collection && nft.collection.address == "2LsU4R3MiGc7VChDerXQjAYf5TZj83LdbXavqgvnBQk5") {
            holder_nft.push(nft)
        }
    }

    //當錢包內沒有任何nft會在接下來獲取ata時出現錯誤，所以在這裡判斷
    let nftmint = null;
    let mint_ata = null;
    let metadataAccount = null;

    if (holder_nft.length > 0) {

        nftmint = (await getMint(connection, holder_nft[0].mintAddress)).address
        mint_ata = (await getOrCreateAssociatedTokenAccount(connection, wallet, holder_nft[0].mintAddress, wallet.publicKey)).address
        metadataAccount = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("metadata"),
                METADATA_PROGRAM.toBuffer(),
                nftmint.toBuffer(),
            ],
            METADATA_PROGRAM
        )[0];
    }

    // 創建staking pool
    const tx = new Transaction();

    // discount settings
    const discountId = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode("discount-prefix"),
            anchor.utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const discountIx = await program.methods
        .initDiscount({
            discountStr: promotionCode,
            authority: wallet.publicKey.value,
            identifier: stakePoolIdentifier
        })
        .accounts({
            discountData: discountId,
            nftMint: nftmint,
            nftTokenAccount: mint_ata,
            metadataAccount: metadataAccount,
            payer: wallet.publicKey.value,
            systemProgram: SystemProgram.programId,
        })
        .instruction();

    tx.add(discountIx);

    const remain = [
        {
            pubkey: discountId,
            isSigner: false,
            isWritable: false,
        },
    ];

    const spl_mint = new PublicKey(payTokenAddress)

    let payer_mint_token_account = null;
    let owner_mint_token_account = null;

    if (spl_mint.toBase58() != NATIVE_MINT.toBase58()) {

        payer_mint_token_account = await getOrCreateAssociatedTokenAccount(
            connection, 
            wallet.publicKey, 
            spl_mint, 
            wallet.publicKey
        ).then((res) => {
            return res.address;
        });
    
        owner_mint_token_account = await getOrCreateAssociatedTokenAccount(
            connection, 
            wallet.publicKey, 
            spl_mint, 
            new PublicKey(process.env.VUE_APP_PAYMENT_OWNER_ADDRESS)
        ).then((res) => {
            return res.address;
        });

    }

    // Stake Pool Info
    const pool = await program.methods
        .initPool({
            identifier: stakePoolIdentifier,
            allowedCollections: collections,
            allowedCreators: [],
            requiresAuthorization: false,
            authority: provider.wallet.publicKey,
            resetOnUnstake: reset_on_stake,
            cooldownSeconds: cooldown_period,
            minStakeSeconds: minimum_stake_duration,
            endDate: end_date,
            stakePaymentInfo: REWARDS_CENTER_ADDRESS,
            unstakePaymentInfo: REWARDS_CENTER_ADDRESS,
        })
        .accounts({
            owner: new PublicKey(process.env.VUE_APP_PAYMENT_OWNER_ADDRESS),// sol destination
            splMint: spl_mint,
            payerMintTokenAccount: payer_mint_token_account,
            ownerMintTokenAccount: owner_mint_token_account,
            stakePool: stakePoolId,
            payer: wallet.publicKey.value,
            systemProgram: SystemProgram.programId,
        })
        .remainingAccounts(remain)
        .instruction();

    tx.add(pool);

    // Reward Info
    let rewardmintId = rewardsInfo.reward_mint_address;

    if (rewardmintId.length > 0) {

        try {

            rewardmintId = new PublicKey(rewardmintId);

            if (rewardmintId.toBase58() == NATIVE_MINT.toBase58()) {
                ElMessage.error('Native Sol Rewards are not supported.');
                activeStep.value = 2;
                return;
            }

        } catch {
            ElMessage.error('Invalid reward mint address');
            activeStep.value = 2;
            return;
        }
    }

    // stake 一天可獲得多少 tokens
    const reward_amount_per_staked_token = rewardsInfo.reward_amount_per_staked_token / 86400;
    const reward_duration_seconds = 1;

    if (rewardmintId && reward_amount_per_staked_token) {

        const rewardDistributorId = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("reward-distributor"),
                stakePoolId.toBuffer(),
                new anchor.BN(0).toArrayLike(Buffer, "le", 8)
            ],
            REWARDS_CENTER_ADDRESS
        )[0];


        // const rewardDistributorInfo = await connection.getTokenSupply(rewardmintId);

        const distributorIx = await program.methods
            .initRewardDistributor({
                identifier: new anchor.BN(0),
                rewardAmount: new anchor.BN(reward_amount_per_staked_token * (10 ** 6)),//mint.value.decimals
                rewardDurationSeconds: new anchor.BN(reward_duration_seconds),
                supply: null,
                defaultMultiplier: new anchor.BN(1),
                multiplierDecimals: 0,
                maxRewardSecondsReceived: null,
            })
            .accounts({
                rewardDistributor: rewardDistributorId,
                stakePool: stakePoolId,
                rewardMint: rewardmintId,//reward_mint_address
                authority: wallet.publicKey,
                payer: wallet.publicKey.value,
            })
            .instruction();

        tx.add(distributorIx);

    }

    await executeTransaction(
        connection,
        tx,
        wallet,
        {
            confirmOptions: {
                skipPreflight: true,
            }
        }
    );

    ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
    await new Promise((resolve) => setTimeout(resolve, 3000));

    ElLoading.service().setText('Init config...');

    await initConfigSetting(
        wallet, connection, idl, program,
        stakePoolIdentifier, configInfo
    );

    ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
    await new Promise((resolve) => setTimeout(resolve, 3000));

    if (collectionDistributor.length > 0) {

        ElLoading.service().setText('Init Collection Gamified Setting...');
        await initCollectionGamifiedSetting(
            wallet, connection, program,
            stakePoolIdentifier, collectionDistributor
        );

        ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
        await new Promise((resolve) => setTimeout(resolve, 3000));

    }

    if (attributeDistributor.length > 0) {

        ElLoading.service().setText('Init Attribute Gamified Setting...');
        await initAttributeGamifiedSetting(
            wallet, connection, program,
            stakePoolIdentifier, attributeDistributor
        );

        ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
        await new Promise((resolve) => setTimeout(resolve, 3000));

    }


    // 創建完成後，跳轉到staking pool頁面
    await ElMessageBox.confirm(
        "Don't forget to fund your pool so your users can get tokens",
        'Fund Your Pool',
        {
            confirmButtonText: 'OK',
            type: 'warning',
            showCancelButton: false,
        }
    ).then(() => {
        window.location.href = '/staking/create/fund/' + stakePoolId.toString();
    }).catch(() => {
        window.location.href = '/staking/create/fund/' + stakePoolId.toString();
    });

}

export const calculateDecimals = async (mintAddress) => {

    try {

        const mint = new PublicKey(mintAddress);

        if (mint.toBase58() == NATIVE_MINT.toBase58()) {
            ElMessage.error('Native Sol Rewards are not supported.');
            return {
                metadata: null,
                enable: false
            };
        }

        const commonStore = useCommonStore();
        const solanaNetwork = commonStore.solanaNetwork;

        const mintData = await axios({
            method: 'get',
            url: 'https://api.shyft.to/sol/v1/token/get_info',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'rpIP_X2kI_jxb0l9'
            },
            params: {
                token_address: mintAddress,
                network: solanaNetwork
            }
        }).then((res) => {
            return res.data.result;
        });

        console.log(mintData);

        if (mintData) {
            return {
                metadata: mintData,
                enable: true
            };
        } else {
            ElMessage.error('Invalid SPL Token Address');
            return {
                metadata: null,
                enable: false
            };
        }

    } catch {

        ElMessage.error('Invalid SPL Token Address');
        return {
            metadata: null,
            enable: false
        };

    }

}

export const getWalletBalance = async (mintAddress) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);

    const userMintAtaId = getAssociatedTokenAddressSync(
        new PublicKey(mintAddress),//rewardmintId,
        wallet.publicKey
    );

    const userMintInfo = await connection.getTokenAccountBalance(userMintAtaId);
    const tokenDecimals = userMintInfo.value.decimals;

    const walletBalanced = Number(userMintInfo.value.amount) / (10 ** tokenDecimals);
    return walletBalanced;

}


const initConfigSetting = async (
    wallet, connection, idl, program,
    stakePoolIdentifier, configInfo
) => {

    // Config Info
    const CONFIG_VALUE_LIMIT = 790;
    const prefixBuffer = Buffer.from('ss', 'utf-8');

    const stakePoolId = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode('stake-pool'),
            anchor.utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const configEntryId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode("config-entry"),
            prefixBuffer,
            Buffer.from(stakePoolIdentifier, "utf-8")
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const colors = {
        primary: configInfo.colors.primary,
        secondary: configInfo.colors.secondary,
        accent: configInfo.colors.accent,
        fontColor: configInfo.colors.fontColor,
        fontColorSecondary: configInfo.colors.fontColorSecondary,
        backgroundSecondary: configInfo.colors.backgroundSecondary,
        fontColorTertiary: configInfo.colors.fontColorTertiary
    }

    const configSetting = {
        name: configInfo.name,
        displayName: configInfo.display_name,
        description: configInfo.description,
        imageUrl: configInfo.image_url,
        stakePoolAddress: new PublicKey(stakePoolId),
        backgroundBannerImageUrl: configInfo.backgroundBannerImageUrl,
        colors: colors,
    }

    const { ...otherObject } = configSetting // stakePoolAddress: _,

    const configString = JSON.stringify({
        stakePoolAddress: stakePoolId.toString(),//Object.keys(stakePool)[0].toString(),
        ...otherObject,
    });

    const configChunks = chunkArray(
        configString.split(''),
        CONFIG_VALUE_LIMIT
    ).map((chunk) => chunk.join(''));

    const initConfigIx = await program.methods
        .initConfigEntry({
            prefix: prefixBuffer,
            key: Buffer.from(stakePoolIdentifier, "utf-8"),
            value: configChunks[0],
            extends: [],
        })
        .accountsStrict({
            configEntry: configEntryId,
            authority: wallet.publicKey,
            systemProgram: SystemProgram.programId,
        })
        .remainingAccounts([
            {
                pubkey: new PublicKey(stakePoolId),
                isSigner: false,
                isWritable: false,
            },
        ])
        .instruction()

    const tx = new Transaction();
    tx.add(initConfigIx);

    await executeTransaction(
        connection,
        tx,
        wallet,
        {
            confirmOptions: {
                skipPreflight: true,
            }
        }
    );

}

const initCollectionGamifiedSetting = async (
    wallet, connection, program,
    stakePoolIdentifier, collectionDistributor
) => {

    // Init Collection Distributor
    const collectionList = [];
    const collection_prob_mult = [];

    for (const index in collectionDistributor) {

        const collectionAddress = collectionDistributor[index].collectionAddress;
        const probability = collectionDistributor[index].probability;
        const multiply = collectionDistributor[index].multiply;

        try {

            const newAddress = new PublicKey(collectionAddress);
            collectionList.push(newAddress);

            collection_prob_mult.push([
                parseFloat(multiply),
                parseInt(probability)
            ]);

        } catch (err) {
            console.log(err);
        }

    }

    const stakePoolId = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode('stake-pool'),
            anchor.utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const collectionMulId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('collection-mul'),
            utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const collectionIx = await program.methods
        .initCollectionMul({
            collectionsMultiply: collectionList,
            multiplyData: collection_prob_mult,
            identifier: stakePoolIdentifier,
            authority: wallet.publicKey,
        })
        .accounts({
            collectionMul: collectionMulId,
            stakePool: stakePoolId,
            payer: wallet.publicKey.value,
            systemProgram: SystemProgram.programId,
        })
        .instruction();

    const tx = new Transaction();
    tx.add(collectionIx);

    await executeTransaction(
        connection,
        tx,
        wallet,
        {
            confirmOptions: {
                skipPreflight: true,
            }
        }
    );
}

const initAttributeGamifiedSetting = async (
    wallet, connection, program,
    stakePoolIdentifier, attributeDistributor
) => {

    // Init Attribute Distributor
    const attributes = [];
    const attribute_prob_mult = [];

    for (const index in attributeDistributor) {

        const collectionAddress = attributeDistributor[index].collectionAddress;
        const trait_type = attributeDistributor[index].attribute.trait_type;
        const trait_value = attributeDistributor[index].attribute.value;
        const probability = attributeDistributor[index].probability;
        const multiply = attributeDistributor[index].multiply;

        // 確認是否都有值
        if (
            collectionAddress != '' &&
            trait_type != '' &&
            trait_value != '' &&
            probability &&
            multiply
        ) {

            try {

                // 測試是否為合法的address
                new PublicKey(collectionAddress);

                attributes.push([
                    collectionAddress,
                    trait_type,
                    trait_value
                ]);

                attribute_prob_mult.push([
                    parseFloat(multiply),
                    parseInt(probability)
                ]);


            } catch (err) {
                console.log(err);
            }

        }

    }

    const stakePoolId = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode('stake-pool'),
            anchor.utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const attributeMulId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('attribute-mul'),
            utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const attributeIx = await program.methods
        .initAttributeMul({
            attributeMultiply: attributes,
            multiplyData: attribute_prob_mult,
            identifier: stakePoolIdentifier,
            authority: wallet.publicKey,
        })
        .accounts({
            attributeMul: attributeMulId,
            stakePool: stakePoolId,
            payer: wallet.publicKey.value,
            systemProgram: SystemProgram.programId,
        })
        .instruction();

    const tx = new Transaction();
    tx.add(attributeIx);

    await executeTransaction(
        connection,
        tx,
        wallet,
        {
            confirmOptions: {
                skipPreflight: true,
            }
        }
    );
}

export const createTooltipsList = [
    {
        id: 1,
        section: 'Pool Info',
        title: 'Creator Address',
        desc: 'Allow for staking any tokens with specified by you creator address(es).'
    },
    {
        id: 2,
        section: 'Pool Info',
        title: 'NFT Collection Address',
        desc: 'Allow for staking any tokens from specified by you NFT collection(s).'
    },
    {
        id: 3,
        section: 'Rewards',
        title: 'Reward mint address',
        desc: 'Specify the mint address of the reward token for stakers that will deposit assets into your Stake Pool.'
    },
    {
        id: 4,
        section: 'Rewards',
        title: 'Reward amount per staked NFT',
        desc: 'Reward amount per staked NFT per period T (Specify the amount of tokens to be distributed per NFT staked for a period of T, e.g. 1 Dust per NFT staked per one period of T.)'
    },
    {
        id: 5,
        section: 'Rewards',
        title: 'Reward duration seconds',
        desc: 'Reward distribution period T / sec (Determine the period of T in unit of second)'
    },
    {
        id: 6,
        section: 'Rewards',
        title: 'Maximum reward duration',
        desc: 'Specify for how long a staked NFT can receive rewards in your pool.'
    },
    {
        id: 7,
        section: 'Features',
        title: 'Minimum stake duration.',
        desc: 'Minimum stake duration.'
    },
    {
        id: 8,
        section: 'Features',
        title: 'Cooldown period',
        desc: 'The cooldown period is the timeframe before the newly unstaked tokens are available to withdraw. If your Stake Pool has no cooldown period, then stakers can unstake and receive their assets at any time.'
    },
    {
        id: 9,
        section: 'Features',
        title: 'Reset on stake',
        desc: 'If active then every time a user stakes a token, then unstakes it and stakes again in your Stake Pool the stake timer will reset rather than accumulate.'
    },
    {
        id: 10,
        section: 'Features',
        title: 'End date',
        desc: 'Select end date for your pool when staking is disabled but claiming rewards and unstaking is still enabled.'
    },
    {
        id: 11,
        section: 'Create',
        title: 'Promotion Code',
        desc: 'Enter the promotion code to get some discount.'
    }
]