import { useWallet } from "solana-wallets-vue";
import { Connection } from "@solana/web3.js";
import { useCommonStore } from "@/store";
let workspace = null;

export const useWorkspace = () => workspace;

export const initWorkspace = () => {

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const wallet = useWallet();
    const connection = new Connection(clusterUrl, "confirmed");

    workspace = {
        wallet,
        connection
    }
}