import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/staking',
    name: 'non_custodial_staking',
    component: () => import('../views/staking/NonCustodialView.vue')
  },
  {
    path: '/staking/create',
    name: 'non_custodial_staking_create',
    component: () => import('../views/staking/create/NonCustodialCreateView.vue')
  },
  {
    path: '/staking/create/fund/:poolPubKey',
    name: 'non_custodial_staking_create_fund',
    component: () => import('../views/staking/create/NonCustodialCreateFundView.vue')
  },
  {
    path: '/staking/edit/:poolPubKey',
    name: 'non_custodial_staking_edit',
    component: () => import('../views/staking/edit/NonCustodialEditView.vue')
  },
  {
    path: '/staking/pool/:poolName',
    name: 'staking_pool',
    component: () => import('../views/staking/pool/NonCustodialPoolView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
