import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import "element-plus/theme-chalk/index.css";
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './assets/css/soft-ui-dashboard.css';
import './assets/css/docs-soft.css';
import './assets/css/modify.css';
import "solana-wallets-vue/styles.css";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app
    .use(ElementPlus)
    .use(createPinia())
    .use(router)
    .mount('#app')
