import { defineStore } from "pinia";

export const useCommonStore = defineStore("common", {
    state: () => ({
        windowWidth: window.innerWidth,
        solanaNetwork: "mainnet-beta",
        // solanaNetwork: "devnet",
        devnetRpcEndpoint: "https://skilled-quick-county.solana-devnet.quiknode.pro/e94c4fcbe22a773f6cfe1b171daa0ce9972521a5/",
        'mainnet-betaRpcEndpoint': "https://mainnet.helius-rpc.com/?api-key=a0754543-6e15-444a-93bd-c65c15026a06",
        // mainnetRpcEndpoint: "https://api.metaplex.solana.com/",
        // webApiUrl: "http://127.0.0.1:3000/v2",
        webApiUrl: "https://api.tdtn.tw/v2",
    }),
});

export const useStakeStore = defineStore("stake", {
    state: () => ({
        stakePoolId: "",
        nftCollectionAddresses: [],
        rewardDistributorInfo: {
            rewardMintAddress: null,
            rewardAmount: null,
            rewardDurationSeconds: null,
            maximumRewardDuration: null
        },
        featuresInfo: {
            minimum_stake_duration: null,
            cooldown_period: null,
            reset_on_stake: false,
            end_date: null
        },
        rewardDistributorFund: {
            depositTokens: "",
            withdrawTokens: ""
        },
        configInfo: {
            name: "",
            display_name: "",
            description: "",
            image_url: "",
            colors: {
                primary: "",
                secondary: "",
                accent: "",
                fontColor: "",
                fontColorSecondary: "",
                backgroundSecondary: "",
                fontColorTertiary: ""
            },
            backgroundBannerImageUrl: ""
        },
        collectionDistributor: [],
        collectionError: [],
        attributeDistributor: [],
        attributeError: [],
        modifyStatus: {
            fund: false,
            pool: false,
            reward: false,
            collection: false,
            attribute: false,
            features: false,
            config: false
        }
    })
});