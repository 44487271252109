// 新增一個input
export const addInput = (inputList) => {
    inputList.push({ address: "" });
    return inputList;
}

// 刪除 input
export const deleteInput = (index, inputList) => {
    inputList.splice(index, 1);
}

// 不允許今日以前之時間
export const disabledDate = (time) => {

    const now = new Date();
    now.setDate(now.getDate() - 10);

    return time.getTime() < now - 8.64e7;

}

export const ellipsisText = (str) => {
    if (str.length > 35) {
        return str.substr(0, 5) + '...' + str.substr(str.length - 5, str.length);
    }
    return str;
}

export const sortArrayOfObject = (data, key) => {
    return data.sort(function (a, b) {
        return a[key] - b[key]
    })
}

// Function to group objects by a specific key
export const groupByCollection = (list, keyToGroup, keyToSum) => {

    return list.reduce((grouped, item) => {

        const groupKey = item[keyToGroup];

        // Initialize an empty object for this group if it doesn't exist
        if (!grouped[groupKey]) {
            grouped[groupKey] = 0;
        }

        // Sum the values within the group
        grouped[groupKey] += parseInt(item[keyToSum]);

        return grouped;
    }, {});

}

export const groupByAttributes = (list) => {

    return list.reduce((grouped, item) => {

        const groupKey = `${item['collectionAddress']}-${item['attribute']['trait_type']}-${item['attribute']['value']}`;

        // Initialize an empty object for this group if it doesn't exist
        if (!grouped[groupKey]) {
            grouped[groupKey] = 0;
        }

        // Sum the values within the group
        grouped[groupKey] += parseInt(item['probability']);

        return grouped;
        
    }, {});

}

export const getUtcDate = () => {

    const now = new Date();

    const utcNow = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
    );

    const utcTime = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        7,
        0,
        0
    );

    const utcDate = new Date(utcTime);

    if (utcNow > utcTime) {
        utcDate.setDate(utcDate.getUTCDate() + 1);
    }

    return utcDate.getTime();
}