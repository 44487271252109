<template>
    <div class="container-fluid py-4 h-min-100">
        <!-- <div class="row" v-if="walletStatus">
            <div class="mb-lg-2" style="max-width: 350px;">
                <div class="card bg-transparent shadow-xl">
                    <div class="overflow-hidden position-relative border-radius-xl"
                        style="background-image: url('../assets/img/curved-images/curved14.jpg');">
                        <span class="mask bg-black" style="opacity: 1;"></span>
                        <div class="card-body position-relative z-index-1 p-4">
                            <p class="text-white text-sm opacity-8 mb-0">Wallet Address</p>
                            <h5 class="text-white mt-0 mb-5 pb-2" id="wallet-address">
                                {{ walletAddress }}</h5>
                            <div class="d-flex">
                                <div class="d-flex">
                                    <div class="me-4">
                                        <p class="text-white text-sm opacity-8 mb-0">Role</p>
                                        <h6 class="text-white mb-0">Normal</h6>
                                    </div>
                                    <div>
                                        <p class="text-white text-sm opacity-8 mb-0">Expires</p>
                                        <h6 class="text-white mb-0">Lifetime</h6>
                                    </div>
                                </div>
                                <div class="ms-auto w-20 d-flex align-items-end justify-content-end">
                                    <img class="w-60 mt-2" src="../assets/img/logos/mastercard.png" alt="logo">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <not-connected-wallet-card v-else /> -->
        <div class="row">
            <div class="col-lg-3">
                <div class="card shadow-xl">
                    <div class="card-body p-3">
                        <h4 class="text-black text-center">Pool Quantity</h4>
                        <h2 class="num-text text-black text-center" v-loading="loading">{{ poolNumber }}</h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card shadow-xl">
                    <div class="card-body p-3">
                        <h4 class="text-black text-center">NFT Released</h4>
                        <h2 class="num-text text-black text-center" v-loading="loading">{{ nftNumber }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-7 mb-lg-0 mb-4" style="max-width: 700px;">
                <div class="card shadow-xl">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="d-flex flex-column h-100">
                                    <p class="mb-1 pt-2 text-bold">Built by developers</p>
                                    <h2 class="font-weight-bolder">Mimir Lab</h2>
                                    <p class="mb-3">Unlocking Web3's Potential with Mimir Lab: Your Gateway to Gamified Staking and Rewards.
                                         with Solana Ecosystems.</p>
                                    <a class="text-body text-sm font-weight-bold icon-move-right mt-auto"
                                        :class="windowWidth <= 475 ? 'mb-3' : 'mb-0'" href="javascript:;">
                                        Read More
                                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-5 ms-auto text-center mt-0 mt-lg-0">
                                <div class="border-radius-lg">
                                    <img class="w-100 position-relative z-index-2 border-radius-lg" src="../assets/logo.png"
                                        alt="rocket">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { computed, onMounted, ref } from 'vue';
import { useWorkspace, poolNum, nftReleaseNum } from '@/composables';
import { useCommonStore } from '@/store';
// import NotConnectedWalletCard from '@/components/NotConnectedWalletCard.vue';

export default {
    name: 'HomeView',
    // components: {
    //     NotConnectedWalletCard
    // },
    setup() {

        const commonStore = useCommonStore();
        const windowWidth = computed(() => commonStore.windowWidth);

        // 取得錢包及錢包地址
        const { wallet } = useWorkspace();
        const walletAddress = computed(() => wallet.publicKey?.value?.toString() || wallet.publicKey?.toString());
        const walletStatus = computed(() => wallet.connected.value);

        const poolNumber = ref(0);
        const nftNumber = ref(0);
        const loading = ref(false);

        onMounted(async () => {
            loading.value = true;
            poolNumber.value = await poolNum();
            nftNumber.value = await nftReleaseNum();
            loading.value = false;
        });

        return {
            windowWidth,
            walletAddress,
            walletStatus,
            poolNumber,
            nftNumber,
            loading
        }

    }
}
</script>

<style scoped>
@import url('../assets/css/HomeView.css');
</style>
  