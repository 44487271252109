import { useWorkspace, useMetaplex, full_config, ellipsisText } from '@/composables';
import { PublicKey, Transaction } from '@solana/web3.js';
import { fetchIdlAccountDataById, withFindOrInitAssociatedTokenAccount } from "@cardinal/common";
import { getAccount, getAssociatedTokenAddressSync } from '@solana/spl-token';
import { utils } from "@coral-xyz/anchor";
import { useCommonStore } from '@/store';
import axios from 'axios';

import dotenv from 'dotenv';
dotenv.config();

const anchor = require('@project-serum/anchor');

const REWARDS_CENTER_ADDRESS = new PublicKey(process.env.VUE_APP_REWARDS_CENTER_ADDRESS);

export const initProgram = async () => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet)
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    return {
        wallet: wallet,
        connection: connection,
        provider: provider,
        idl: idl
    }

}

export const SpecificPool = async (program, poolName) => {

    const connection = program.connection;
    const idl = program.idl;

    // 確認是使用地址還是名稱尋找 Pool
    let pool_data;
    try {

        const stakePoolAddress = new PublicKey(poolName);

        const pool = await fetchIdlAccountDataById(
            connection,
            [stakePoolAddress],
            REWARDS_CENTER_ADDRESS,
            idl
        );

        pool_data = pool[Object.keys(pool)[0]]

    } catch {

        // 不是地址，是名稱

        const configDatas = await full_config(idl, connection);

        for (const config of configDatas) {
           
            const config_parsed = config.parsed.value.match(/\{[^}]*\}/g)
            let latest_config = ""

            if (config_parsed) {
                latest_config = JSON.parse(config_parsed[config_parsed.length - 1]+"}");
            }
    
            if (latest_config.name == poolName || latest_config.stakePoolAddress == poolName) {
    
                const pool = await fetchIdlAccountDataById(
                    connection,
                    [new PublicKey(latest_config.stakePoolAddress)],
                    REWARDS_CENTER_ADDRESS,
                    idl
                );
                pool_data = pool[Object.keys(pool)[0]]
    
            }
        }

    }

    const stakePoolId = pool_data.pubkey.toBase58();

    const rewardDistributorId = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode("reward-distributor"),
            new PublicKey(stakePoolId).toBuffer(),
            new anchor.BN(0).toArrayLike(Buffer, "le", 8)
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const distributor_data = await fetchIdlAccountDataById(
        connection,
        [rewardDistributorId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    const reward_distributor = distributor_data[Object.keys(distributor_data)[0]];

    return {
        pool_data: pool_data,
        reward_distributor: reward_distributor
    };
}

export const loadReward = async (program, poolData) => {

    const wallet = program.wallet;
    const connection = program.connection;

    const reward = poolData.reward_distributor;
    const reward_parsed = reward.parsed;

    const rewardAmount = parseInt(reward_parsed.rewardAmount.toString());
    console.log(rewardAmount);
    const rewardDurationSeconds = parseInt(reward_parsed.rewardDurationSeconds.toString());
    console.log(rewardDurationSeconds);

    // 2.treasury
    const tx = new Transaction()
    const rewardDistributorAtaId = await withFindOrInitAssociatedTokenAccount(
        tx,
        connection,
        reward_parsed.rewardMint,
        reward.pubkey,
        wallet.publicKey,
        true
    );

    let treasury_amount = 0;
    let decimals = 0;
    try {

        const rewardDistributorInfo = await connection.getTokenAccountBalance(rewardDistributorAtaId);

        decimals = rewardDistributorInfo.value.decimals;
        treasury_amount = Number(rewardDistributorInfo.value.amount) / (10 ** decimals);

    } catch (error) {
        console.log(error);
    }

    console.log(rewardAmount);
    console.log(rewardDurationSeconds);

    const amount = rewardAmount / (10 ** decimals)

    const reward_per_day = (amount / rewardDurationSeconds) * 86400;
    const total_staked = poolData.pool_data.parsed.totalStaked;

    return {
        reward_per_day: reward_per_day,
        treasury_amount: treasury_amount,
        total_staked: total_staked
    }

}

export const loadData = async (program, poolData, rewardData) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const connection = program.connection;
    const idl = program.idl;

    const pool_data = poolData.pool_data;
    const pool_parsed = pool_data.parsed;

    const stakingProgram = REWARDS_CENTER_ADDRESS;
    const stakePoolId = pool_data.pubkey;

    // 目前每日可獲得獎勵水位
    let total_rate = 0;
    // 上次 claim 的時間
    let last_claim;

    // 已質押的 nft
    const staked_nft = [];
    // 尚未質押的 nft
    const unstaked_nft = [];

    try {

        const metaplex = useMetaplex();

        const owner = wallet.publicKey;
        const all_nft = await metaplex.nfts().findAllByOwner({
            owner: owner
        });

        const last_update = [];
        const allowedCollections = pool_parsed.allowedCollections.map((item) => {
            return item.toBase58();
        });
        const allowedCreators = pool_parsed.allowedCreators.map((item) => {
            return item.toBase58();
        });

        for (const nft of all_nft) {

            let isValid = false;

            if (nft.collection && allowedCollections.includes(nft.collection.address.toBase58())) {

                isValid = true;

            } else {

                for (const creator of nft.creators) {
                    if (allowedCreators.includes(creator.address.toBase58())) {
                        isValid = true;
                        break;
                    }
                }

            }

            if (isValid) {

                const userAtaId = getAssociatedTokenAddressSync(
                    nft.mintAddress,
                    owner
                );

                //分別獲取使用者nft以及program esc進行比對
                const userAta = await getAccount(connection, userAtaId);
                const esc = PublicKey.findProgramAddressSync(
                    [utils.bytes.utf8.encode('escrow'), owner.toBuffer()],
                    stakingProgram
                )[0];

                if (userAta.delegate) { // 在有 delegate 的情況下有可能為 list 至 market place，需要確認該 escrow 地址
                    
                    if (userAta.delegate.toBase58() == esc.toBase58()) {

                        const nftWithMetadata = await metaplex.nfts().findByMint({
                            mintAddress: new PublicKey(userAta.mint.toString())
                        });

                        const isFungible = false;

                        //獲取各個已經stake的時間
                        const stakeEntryId = PublicKey.findProgramAddressSync(
                            [
                                utils.bytes.utf8.encode("stake-entry"),
                                stakePoolId.toBuffer(),
                                userAta.mint.toBuffer(), // 不能在還沒有設定時這樣寫
                                owner && isFungible ? owner.toBuffer() : PublicKey.default.toBuffer(),
                            ],
                            stakingProgram
                        )[0];

                        const entry = await fetchIdlAccountDataById(
                            connection,
                            [stakeEntryId],
                            stakingProgram,
                            idl
                        );

                        if (entry[Object.keys(entry)[0]]) {

                            nftWithMetadata.lastUpdatedAt = entry[Object.keys(entry)[0]].parsed.lastUpdatedAt.toString();
                            nftWithMetadata.isSelected = false;
    
                            staked_nft.push(nftWithMetadata);

                            //比較最後更新的最大值
                            last_update.push(nftWithMetadata.lastUpdatedAt)
                            const maxTimestamp = Math.max(...last_update);
                            last_claim = new Date(maxTimestamp * 1000)

                            //total rate
                            total_rate += rewardData.reward_per_day;
                            
                        }

                    }

                } else {

                    const nftWithMetadata = await metaplex.nfts().findByMint({
                        mintAddress: new PublicKey(userAta.mint.toString())
                    });

                    if(nftWithMetadata.collection != null) {

                        nftWithMetadata.isSelected = false;
                        unstaked_nft.push(nftWithMetadata);
                    }

                }
            }
        }

    } catch (error) {
        console.log(error);
    }

    return {
        staked_nft: staked_nft,
        unstaked_nft: unstaked_nft,
        last_claim: last_claim,
        total_rate: total_rate
    }

}

export const loadConfig = async (program, poolData) => {

    const connection = program.connection;
    const idl = program.idl;

    const pool_data = poolData.pool_data;
    const pool_parsed = pool_data.parsed;

    const keyBuffer = Buffer.from(pool_parsed.identifier, 'utf-8')//need to associate to the pool ientifier
    const prefixBuffer = Buffer.from('ss', 'utf-8')

    const configEntryId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode("config-entry"),
            prefixBuffer,
            keyBuffer
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const config_data = await fetchIdlAccountDataById(
        connection,
        [configEntryId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    if (config_data[Object.keys(config_data)[0]]) {

        // 這裡是stringn所以用正規表達式拆解所有dict然後獲取最後一次更新內容
        const latest_config_data = config_data[Object.keys(config_data)[0]].parsed.value.match(/\{[^}]*\}/g);
        const config = JSON.parse(latest_config_data[latest_config_data.length - 1]+'}');

        if (config.displayName == "") {
            config.displayName = ellipsisText(pool_data.pubkey.toBase58());
        }

        return config;

    } else {

        return {
            name: "",
            displayName: ellipsisText(pool_data.pubkey.toBase58()),
            description: "",
            imageUrl: "",
            stakePoolAddress: "",
        }

    }

}

export const loadClaimTime = async (poolAddress) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const queryResult = await axios({
        method: 'get',
        url: webApiUrl + '/mimir/find',
        params: {
            wallet_address: wallet.publicKey.toBase58(),
            pool_address: poolAddress
        }
    }).then((response) => {
        const result = response.data.Result;
        return result?.last_claim_time;
    }).catch((error) => {
        console.log(error);
        return null;
    });

    return queryResult;

}

export const upsertClaimTime = async (poolAddress) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const queryResult = await axios({
        method: 'post',
        url: webApiUrl + '/mimir/upsert',
        data: {
            wallet_address: wallet.publicKey.toBase58(),
            pool_address: poolAddress
        }
    }).then((response) => {
        const result = response.data.Result;
        return result;
    }).catch((error) => {
        console.log(error);
        return null;
    });

    return queryResult;

}