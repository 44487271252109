import { useWorkspace } from '@/composables';
import {
    Transaction,
    PublicKey,
    SystemProgram,
} from '@solana/web3.js';
import {
    executeTransaction,
    fetchIdlAccountDataById,
    chunkArray,
    withFindOrInitAssociatedTokenAccount,
    findAta,
    fetchIdlAccount
} from "@cardinal/common";
import {
    getAssociatedTokenAddressSync,
    createTransferInstruction,
    TOKEN_PROGRAM_ID,
    createAssociatedTokenAccountIdempotentInstruction,
    getAccount
} from "@solana/spl-token";
const anchor = require('@project-serum/anchor');
import { utils, BN, BorshAccountsCoder } from "@coral-xyz/anchor";
import { useCommonStore, useStakeStore } from '@/store';
import { ElMessage, ElLoading } from 'element-plus';

import dotenv from 'dotenv';
dotenv.config();

const CONFIG_VALUE_LIMIT = 790;
const LOOKUP_BATCH_SIZE = 5000;
const batchSize = 4;
const REWARDS_CENTER_ADDRESS = new PublicKey(process.env.VUE_APP_REWARDS_CENTER_ADDRESS);

export const checkError = async (connection, idl) => {

    const stakeStore = useStakeStore();
    const modifyStatus = stakeStore.modifyStatus;

    let updateStatus = false;
    Object.values(modifyStatus).forEach((value) => {
        updateStatus = updateStatus || value;
    });

    if (!updateStatus) {
        ElMessage.info('There is no update data.');
        ElLoading.service().close();
        return true;
    }

    if (modifyStatus.pool || modifyStatus.features) {

        const nftCollectionAddresses = stakeStore.nftCollectionAddresses;

        if (nftCollectionAddresses.length == 0) {
            ElMessage.error('Please add at least one NFT Collection Address');
            ElLoading.service().close();
            return true;
        }

        // Pool Info
        for (let i = 0; i < nftCollectionAddresses.length; i++) {
            try {
                new PublicKey(nftCollectionAddresses[i].address);
            } catch {
                ElMessage.error('NFT Collection Address is invalid');
                ElLoading.service().close();
                return true;
            }
        }

    }

    if (modifyStatus.reward) {

        const rewardDistributorInfo = stakeStore.rewardDistributorInfo;

        if (rewardDistributorInfo.rewardAmount == '') {

            ElMessage.error('Please input the reward amount per staked token.');
            ElLoading.service().close();
            return true;

        }

        // Reward Info
        let rewardmintId = rewardDistributorInfo.rewardMintAddress;

        if (rewardmintId != null) {
            try {
                rewardmintId = new PublicKey(rewardmintId);
            } catch {
                ElMessage.error('Reward Mint Address is invalid');
                ElLoading.service().close();
                return true;
            }
        }


    }

    if (modifyStatus.collection) {

        if (stakeStore.collectionError.length > 0) {
            ElMessage.error('Collection Reward Distributor Error: Invalid Settings');
            ElLoading.service().close();
            return true;
        }

        const collectionDistributor = stakeStore.collectionDistributor;

        for (const index in collectionDistributor) {

            const collectionAddress = collectionDistributor[index].collectionAddress;

            try {
                new PublicKey(collectionAddress);
            } catch (err) {
                ElMessage.error('Reward Distributor Error: Collection Address is invalid');
                ElLoading.service().close();
                return true;
            }

        }

    }

    if (modifyStatus.attribute) {

        if (stakeStore.attributeError.length > 0) {
            ElMessage.error('Attributes Reward Distributor Error: Invalid Settings');
            ElLoading.service().close();
            return true;
        }

        const attributeDistributor = stakeStore.attributeDistributor;

        for (const index in attributeDistributor) {

            const collectionAddress = attributeDistributor[index].collectionAddress;
            const trait_type = attributeDistributor[index].attribute.trait_type;
            const trait_value = attributeDistributor[index].attribute.value;
            const probability = attributeDistributor[index].probability;
            const multiply = attributeDistributor[index].multiply;

            // 確認是否都有值
            if (
                collectionAddress != '' &&
                trait_type != '' &&
                trait_value != '' &&
                probability >= 0 &&
                multiply >= 0
            ) {

                try {
                    // 測試是否為合法的address
                    new PublicKey(collectionAddress);
                } catch (err) {
                    ElMessage.error('Reward Distributor Error: Collection Address is invalid');
                    ElLoading.service().close();
                    return true;
                }

            } else {
                ElMessage.error('Reward Distributor Error: Attribute is invalid');
                ElLoading.service().close();
                return true;
            }

        }

    }

    if (modifyStatus.config) {

        const configInfo = stakeStore.configInfo;

        let errorMsg;

        if (configInfo.display_name == "") {

            errorMsg = 'Please input the pool name.';

        } else if (configInfo.name == "") {

            errorMsg = 'Please input the url name.';

        } else if (configInfo.name.length < 3 || configInfo.name.length > 20) {

            errorMsg = 'The url name must be between 3 and 20 characters.';

        } else if (configInfo.name.includes(' ')) {

            errorMsg = 'The url name cannot contain spaces.';

        } else if (configInfo.description == "") {

            errorMsg = 'Please input the description.';

        } else if (configInfo.image_url == "") {

            errorMsg = 'Please input the image url.';

        }

        if (errorMsg) {
            ElMessage.error(errorMsg);
            ElLoading.service().close();
            return true;
        }

        // 因為資料是合併成字串的關係，無法限制輸入的name在program是唯一
        const configList = await full_config(idl, connection);

        for (const config of configList) {

            const config_parsed = config.parsed.value.match(/\{[^}]*\}/g);

            if (config_parsed) {

                const latest_config = JSON.parse(config_parsed[config_parsed.length - 1]+"}");

                if (latest_config.name == configInfo.name && latest_config.stakePoolAddress != stakeStore.stakePoolId) {
                    ElMessage.error('The url name already exists');
                    ElLoading.service().close();
                    return true;
                }

            }

        }

    }

    return false;

}

export const updateStakingPool = async () => {

    const loading = ElLoading.service({
        lock: true,
        text: 'Init Update...',
        background: 'rgba(0, 0, 0, 0.8)'
    });

    // Stake Info
    const stakeStore = useStakeStore();
    const stakePoolId = stakeStore.stakePoolId;
    const modifyStatus = stakeStore.modifyStatus;

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);

    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);
    const program = new anchor.Program(idl, REWARDS_CENTER_ADDRESS, provider);

    // Check Error
    const errorStatus = await checkError(connection, idl);

    if (!errorStatus) {

        const pool_data = await fetchIdlAccountDataById(
            connection,
            [new PublicKey(stakePoolId)],
            REWARDS_CENTER_ADDRESS,
            idl
        );

        // Find Pubkey by seed
        const rewardDistributorId = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("reward-distributor"),
                new PublicKey(stakePoolId).toBuffer(),
                new anchor.BN(0).toArrayLike(Buffer, "le", 8)
            ],
            REWARDS_CENTER_ADDRESS
        )[0];

        // Find data by ID
        const reward_data = await fetchIdlAccountDataById(
            connection,
            [rewardDistributorId],
            REWARDS_CENTER_ADDRESS,
            idl
        )

        // 需要有pool才有edit pool，所以不需像rewardDistributor那樣判斷，也不需同時有collectiond和creators才能更新
        if (modifyStatus.pool || modifyStatus.features) {

            const nftCollectionAddresses = stakeStore.nftCollectionAddresses;

            // Pool Info
            const collections = [];
            for (let i = 0; i < nftCollectionAddresses.length; i++) {
                try {
                    const newAddress = new PublicKey(nftCollectionAddresses[i].address);
                    collections.push(newAddress);
                } catch {
                    ElMessage.error('NFT Collection Address is invalid');
                    loading.close();
                    return;
                }
            }

            const featuresInfo = stakeStore.featuresInfo;

            try {

                await updateCollectionInfo(
                    collections,
                    featuresInfo,
                    stakePoolId,
                    wallet,
                    provider,
                    program
                );

                ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
                await new Promise((resolve) => setTimeout(resolve, 3000));

            } catch (err) {

                ElMessage.error('Update failed: ' + err.message);
                loading.close();
                return;

            }

        }

        if (modifyStatus.reward) {

            const rewardDistributorInfo = stakeStore.rewardDistributorInfo;

            try {

                await updateBasicRewardDistributor(
                    rewardDistributorId,
                    reward_data,
                    rewardDistributorInfo,
                    stakePoolId,
                    connection,
                    provider,
                    program
                );

                ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
                await new Promise((resolve) => setTimeout(resolve, 3000));

            } catch (err) {

                ElMessage.error('Update failed: ' + err.message);
                loading.close();
                return;

            }

        }

        if (modifyStatus.collection) {

            // collection
            const identifier = pool_data[Object.keys(pool_data)[0]].parsed.identifier.toString();
            const collectionDistributor = stakeStore.collectionDistributor;

            try {

                await updateCollectionRewardDistributor(
                    identifier,
                    collectionDistributor,
                    stakePoolId,
                    wallet,
                    connection,
                    idl,
                    provider,
                    program
                );

                ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
                await new Promise((resolve) => setTimeout(resolve, 3000));

            } catch (err) {

                ElMessage.error('Update failed: ' + err.message);
                loading.close();
                return;

            }

        }

        if (modifyStatus.attribute) {

            // attribute
            const identifier = pool_data[Object.keys(pool_data)[0]].parsed.identifier.toString();
            const attributeDistributor = stakeStore.attributeDistributor;

            try {

                await updateAttributeRewardDistributor(
                    identifier,
                    attributeDistributor,
                    stakePoolId,
                    connection,
                    idl,
                    provider,
                    program
                );

                ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
                await new Promise((resolve) => setTimeout(resolve, 3000));

            } catch (err) {

                ElMessage.error('Update failed: ' + err.message);
                loading.close();
                return;

            }

        }

        // config
        if (modifyStatus.config) {

            const configInfo = stakeStore.configInfo;

            //need to associate to the pool ientifier
            const keyBuffer = Buffer.from(pool_data[Object.keys(pool_data)[0]].parsed.identifier, 'utf-8');

            const prefixBuffer = Buffer.from('ss', 'utf-8');

            try {

                await updateConfigInfo(
                    prefixBuffer,
                    keyBuffer,
                    configInfo,
                    stakePoolId,
                    wallet,
                    connection,
                    provider,
                    idl,
                    program
                );

                ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
                await new Promise((resolve) => setTimeout(resolve, 3000));

            } catch (err) {

                ElMessage.error('Update failed: ' + err.message);
                loading.close();
                return;

            }

        }

        await new Promise((resolve) => setTimeout(resolve, 7000));
        window.location.reload();

    }

}

const updateCollectionInfo = async (collections, featuresInfo, stakePoolId, wallet, provider, program) => {

    ElLoading.service().setText('Update Collection Settings...');

    // Features Info
    const minimum_stake_duration = featuresInfo.minimum_stake_duration;
    const cooldown_period = featuresInfo.cooldown_period;
    const reset_on_stake = featuresInfo.reset_on_stake;
    let end_date = featuresInfo.end_date;
    if (end_date != null) {
        end_date = new anchor.BN((new Date(end_date)).getTime() / 1000);
    }

    const pool = await program.methods
        .updatePool({
            allowedCollections: collections,
            allowedCreators: [],
            requiresAuthorization: false,
            authority: provider.wallet.publicKey,
            resetOnUnstake: reset_on_stake,
            cooldownSeconds: cooldown_period,
            minStakeSeconds: minimum_stake_duration,
            endDate: end_date,
            stakePaymentInfo: REWARDS_CENTER_ADDRESS,
            unstakePaymentInfo: REWARDS_CENTER_ADDRESS,
        })
        .accounts({
            stakePool: new PublicKey(stakePoolId),
            payer: wallet.publicKey,
            authority: provider.wallet.publicKey,
            systemProgram: SystemProgram.programId
        })
        .instruction();

    const tx = new Transaction();
    tx.add(pool);

    await executeTransaction(
        provider.connection,
        tx,
        provider.wallet.wallet.value,
        {
            confirmOptions: {
                skipPreflight: false
            }
        }
    );

}

const updateBasicRewardDistributor = async (rewardDistributorId, reward_data, rewardDistributorInfo, stakePoolId, connection, provider, program) => {

    // Reward Info
    const rewardmintId = new PublicKey(rewardDistributorInfo.rewardMintAddress);

    // const reward_duration_seconds = 1;
    const reward_duration_seconds = parseInt(rewardDistributorInfo.rewardDurationSeconds);
    console.log(reward_duration_seconds);

    const reward_amount_per_staked_token = (rewardDistributorInfo.rewardAmount / 86400) * reward_duration_seconds;
    console.log(reward_amount_per_staked_token);

    //需要三個都有資料才能完成初始化
    if (rewardmintId && reward_amount_per_staked_token) {

        ElLoading.service().setText('Update Basic Reward Distributor...');

        const tx = new Transaction();

        const rewardDistributorInfo = await connection.getTokenSupply(rewardmintId);
        const decimals = rewardDistributorInfo.value.decimals;

        //如果reward資料為空代表沒有初始化過
        if (Object.keys(reward_data) == '') {

            const distributor = await program.methods
                .initRewardDistributor({
                    identifier: new BN(0),
                    rewardAmount: new BN(reward_amount_per_staked_token * (10 ** decimals)),
                    rewardDurationSeconds: new BN(reward_duration_seconds),
                    supply: null,
                    defaultMultiplier: new BN(1),
                    multiplierDecimals: 0,
                    maxRewardSecondsReceived: null,
                })
                .accounts({
                    rewardDistributor: rewardDistributorId,
                    stakePool: new PublicKey(stakePoolId),
                    rewardMint: rewardmintId,
                    authority: provider.wallet.publicKey,
                    payer: provider.wallet.publicKey,
                })
                .instruction();

            tx.add(distributor);

        } else {

            const distributor = await program.methods
                .updateRewardDistributor({
                    rewardAmount: new BN(reward_amount_per_staked_token * (10 ** decimals)),
                    rewardDurationSeconds: new BN(reward_duration_seconds),
                    defaultMultiplier: new BN(1),
                    multiplierDecimals: 0,
                    maxRewardSecondsReceived: null,
                    claimRewardsPaymentInfo: REWARDS_CENTER_ADDRESS,
                })
                .accounts({
                    rewardDistributor: rewardDistributorId,
                    authority: provider.wallet.publicKey,
                })
                .instruction();

            tx.add(distributor);

        }

        await executeTransaction(
            provider.connection,
            tx,
            provider.wallet.wallet.value,
            {
                confirmOptions: {
                    skipPreflight: false
                }
            }
        );

    }

}

const updateCollectionRewardDistributor = async (identifier, collectionDistributor, stakePoolId, wallet, connection, idl, provider, program) => {

    ElLoading.service().setText('Update Collection Gamified Settings...');

    const collectionMulId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('collection-mul'),
            utils.bytes.utf8.encode(identifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const collectionMul = await fetchIdlAccountDataById(
        connection,
        [collectionMulId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    const collections = [];
    const prob_mult = [];

    for (const index in collectionDistributor) {

        const collectionAddress = collectionDistributor[index].collectionAddress;
        const probability = collectionDistributor[index].probability;
        const multiply = collectionDistributor[index].multiply;

        try {

            const newAddress = new PublicKey(collectionAddress);
            collections.push(newAddress);

            prob_mult.push([
                parseFloat(multiply).toFixed(2),
                parseInt(probability)
            ]);

        } catch (err) {
            console.log(err);
        }

    }

    const tx = new Transaction();

    if (collectionMul[Object.keys(collectionMul)[0]]) {

        // 如果有資料，則使用 update 方法
        const collection_ix = await program.methods
            .updateCollectionMul({
                collectionsMultiply: collections,
                multiplyData: prob_mult,
                identifier: identifier,
                authority: wallet.publicKey
            })
            .accounts({
                authority: provider.wallet.publicKey,
                collectionMul: collectionMulId,
                payer: provider.wallet.publicKey,
                systemProgram: SystemProgram.programId,
            })
            .instruction();

        tx.add(collection_ix);

    } else {

        // 如果沒有資料，則使用 init 方法
        const collection_ix = await program.methods
            .initCollectionMul({
                collectionsMultiply: collections,
                multiplyData: prob_mult,
                identifier: identifier,
                authority: wallet.publicKey,
            })
            .accounts({
                collectionMul: collectionMulId,
                stakePool: new PublicKey(stakePoolId),
                payer: provider.wallet.publicKey,
                systemProgram: SystemProgram.programId,
            })
            .instruction();

        tx.add(collection_ix);

    }

    await executeTransaction(
        provider.connection,
        tx,
        provider.wallet.wallet.value,
        {
            confirmOptions: {
                skipPreflight: false
            }
        }
    );

}

const updateAttributeRewardDistributor = async (identifier, attributeDistributor, stakePoolId, connection, idl, provider, program) => {

    ElLoading.service().setText('Update Attribute Gamified Settings...');

    const attributeMulId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('attribute-mul'),
            utils.bytes.utf8.encode(identifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const attributeMul = await fetchIdlAccountDataById(
        connection,
        [attributeMulId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    const attributes = [];
    const prob_mult = [];

    for (const index in attributeDistributor) {

        const collectionAddress = attributeDistributor[index].collectionAddress;
        const trait_type = attributeDistributor[index].attribute.trait_type;
        const trait_value = attributeDistributor[index].attribute.value;
        const probability = attributeDistributor[index].probability;
        const multiply = attributeDistributor[index].multiply;

        // 確認是否都有值
        if (
            collectionAddress != '' &&
            trait_type != '' &&
            trait_value != '' &&
            probability &&
            multiply
        ) {

            try {

                // 測試是否為合法的address
                new PublicKey(collectionAddress);

                attributes.push([
                    collectionAddress,
                    trait_type,
                    trait_value
                ]);

                prob_mult.push([
                    parseFloat(multiply),
                    parseInt(probability)
                ]);


            } catch (err) {
                console.log(err);
            }

        }

    }

    const tx = new Transaction();

    if (attributeMul[Object.keys(attributeMul)[0]]) {

        const attribute_ix = await program.methods
            .updateAttributeMul({
                attributeMultiply: attributes,
                multiplyData: prob_mult,
                identifier: identifier,
                authority: provider.wallet.publicKey
            })
            .accounts({
                attributeMul: attributeMulId,
                payer: provider.wallet.publicKey,
                systemProgram: SystemProgram.programId,
            })
            .instruction();

        tx.add(attribute_ix);

    } else {

        const attribute_ix = await program.methods
            .initAttributeMul({
                attributeMultiply: attributes,
                multiplyData: prob_mult,
                identifier: identifier,
                authority: provider.wallet.publicKey,
            })
            .accounts({
                attributeMul: attributeMulId,
                stakePool: new PublicKey(stakePoolId),
                payer: provider.wallet.publicKey,
                systemProgram: SystemProgram.programId,
            })
            .instruction();

        tx.add(attribute_ix)

    }

    await executeTransaction(
        provider.connection,
        tx,
        provider.wallet.wallet.value,
        {
            confirmOptions: {
                skipPreflight: false
            }
        }
    );

}

const updateConfigInfo = async (prefixBuffer, keyBuffer, configInfo, stakePoolId, wallet, connection, provider, idl, program) => {

    ElLoading.service().setText('Update Pool Info...');

    // Config Info
    const colors =  {
        primary: configInfo.colors.primary,
        secondary: configInfo.colors.secondary,
        accent: configInfo.colors.accent,
        fontColor: configInfo.colors.fontColor,
        fontColorSecondary: configInfo.colors.fontColorSecondary,
        backgroundSecondary: configInfo.colors.backgroundSecondary,
        fontColorTertiary: configInfo.colors.fontColorTertiary
    }
    const StakePoolMetadata = {
        name: configInfo.name,
        displayName: configInfo.display_name,
        description: configInfo.description,
        imageUrl: configInfo.image_url,
        stakePoolAddress: new PublicKey(stakePoolId),
        backgroundBannerImageUrl: configInfo.backgroundBannerImageUrl,
        colors: colors,
    }

    const { ...otherObject } = StakePoolMetadata // stakePoolAddress: _,

    const configString = JSON.stringify({
        stakePoolAddress: stakePoolId.toString(),//Object.keys(stakePool)[0].toString(),
        ...otherObject,
    })

    const configChunks = chunkArray(
        configString.split(''),
        CONFIG_VALUE_LIMIT
    ).map((chunk) => chunk.join(''));

    const configEntryId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode("config-entry"),
            prefixBuffer,
            keyBuffer
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const config_data = await fetchIdlAccountDataById(
        connection,
        [configEntryId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    const tx = new Transaction();

    // 同reward方法判斷
    if (Object.keys(config_data) == '') {

        const initIx = await program.methods
            .initConfigEntry({
                prefix: prefixBuffer,
                key: keyBuffer,
                value: configChunks[0],
                extends: [],
            })
            .accountsStrict({
                configEntry: configEntryId,
                authority: wallet.publicKey,
                systemProgram: SystemProgram.programId,
            })
            .remainingAccounts([
                {
                    pubkey: new PublicKey(stakePoolId),
                    isSigner: false,
                    isWritable: false,
                },
            ])
            .instruction()

        tx.add(initIx)

    } else {

        const updateIx = await program.methods
            .updateConfigEntry({
                value: configChunks[0],
                extends: [],
                append: true,
            })
            .accountsStrict({
                configEntry: configEntryId,
                authority: wallet.publicKey,
                systemProgram: SystemProgram.programId,
            })
            .remainingAccounts([
                {
                    pubkey: new PublicKey(stakePoolId),
                    isSigner: false,
                    isWritable: false,
                },
            ])
            .instruction()

        tx.add(updateIx)
    }

    await executeTransaction(
        provider.connection,
        tx,
        provider.wallet.wallet.value,
        {
            confirmOptions: {
                skipPreflight: false
            }
        }
    );

}

export const depositToken = async (stakePoolId) => {

    const loading = ElLoading.service({
        lock: true,
        text: 'Deposit Tokens...',
        background: 'rgba(0, 0, 0, 0.8)'
    });

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];
    const connection = new anchor.web3.Connection(clusterUrl);

    const provider = new anchor.AnchorProvider(connection, wallet);
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const stakeStore = useStakeStore();

    // deposit amount
    const depositAmount = parseFloat(stakeStore.rewardDistributorFund.depositTokens);

    // Transfer fund
    if (depositAmount > 0) {

        const tx = new Transaction();

        // Find Pubkey by seed
        const rewardDistributorId = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("reward-distributor"),
                new PublicKey(stakePoolId).toBuffer(),
                new anchor.BN(0).toArrayLike(Buffer, "le", 8)
            ],
            REWARDS_CENTER_ADDRESS
        )[0];

        // Find data by ID
        const reward_data = await fetchIdlAccountDataById(
            connection,
            [rewardDistributorId],
            REWARDS_CENTER_ADDRESS,
            idl
        )

        const mint = reward_data[Object.keys(reward_data)[0]].parsed.rewardMint

        const userRewardMintAta = getAssociatedTokenAddressSync(
            mint,//rewardmintId,
            wallet.publicKey
        );

        const rewardDistributorAtaId = await withFindOrInitAssociatedTokenAccount(
            tx,
            connection,
            mint,
            rewardDistributorId,
            wallet.publicKey,
            true
        );

        const rewardMintInfo = await connection.getTokenSupply(mint);
        const decimals = rewardMintInfo.value.decimals;

        tx.add(
            createTransferInstruction(
                userRewardMintAta,
                rewardDistributorAtaId,
                wallet.publicKey,
                depositAmount * (10 ** decimals)
            )
        );

        try {

            await executeTransaction(
                provider.connection,
                tx,
                provider.wallet.wallet.value,
                {
                    confirmOptions: { skipPreflight: false }
                }
            );

            ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
            await new Promise((resolve) => setTimeout(resolve, 10000));
            if (window.location.pathname.includes('/staking/create/fund')) {
                window.location.href = '/staking';
            } else {
                window.location.reload();
            }

        } catch (err) {

            ElMessage.error('Deposit failed: ' + err.message);
            loading.close();

        }

    } else {

        ElMessage.error('Please enter the amount of tokens to deposit');
        loading.close();

    }

}

export const withdrawToken = async (stakePoolId) => {

    const loading = ElLoading.service({
        lock: true,
        text: 'Withdraw Tokens...',
        background: 'rgba(0, 0, 0, 0.8)'
    });

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);

    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);
    const program = new anchor.Program(idl, REWARDS_CENTER_ADDRESS, provider);

    const stakeStore = useStakeStore();

    // withdraw amount
    const withdrawAmount = parseFloat(stakeStore.rewardDistributorFund.withdrawTokens);

    if (withdrawAmount > 0) {

        const tx = new Transaction();

        // Find Pubkey by seed
        const rewardDistributorId = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("reward-distributor"),
                new PublicKey(stakePoolId).toBuffer(),
                new anchor.BN(0).toArrayLike(Buffer, "le", 8)
            ],
            REWARDS_CENTER_ADDRESS
        )[0];

        // Find data by ID
        const reward_data = await fetchIdlAccountDataById(
            connection,
            [rewardDistributorId],
            REWARDS_CENTER_ADDRESS,
            idl
        )

        const reward = reward_data[Object.keys(reward_data)[0]]

        const rewardDistributorTokenAccount = await findAta(
            reward.parsed.rewardMint,
            reward.pubkey,
            true
        );

        const authorityTokenAccount = await withFindOrInitAssociatedTokenAccount(
            tx,
            connection,
            reward.parsed.rewardMint,
            provider.wallet.publicKey,
            provider.wallet.publicKey,
            true
        );

        const authorityTokenInfo = await connection.getTokenAccountBalance(authorityTokenAccount);
        const decimals = authorityTokenInfo.value.decimals;

        const reclaim = await program.methods
            .reclaimFunds(new BN(withdrawAmount * (10 ** decimals)))
            .accounts({
                rewardDistributor: reward.pubkey,
                rewardDistributorTokenAccount: rewardDistributorTokenAccount,
                authorityTokenAccount: authorityTokenAccount,
                authority: provider.wallet.publicKey.value,
                tokenProgram: TOKEN_PROGRAM_ID,
            })
            .instruction();

        tx.add(reclaim);

        try {

            await executeTransaction(
                provider.connection,
                tx,
                provider.wallet.wallet.value,
                {
                    confirmOptions: { skipPreflight: false }
                }
            );

            ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
            await new Promise((resolve) => setTimeout(resolve, 10000));
            window.location.reload();


        } catch (err) {

            ElMessage.error('Withdraw failed: ' + err.message);
            loading.close();

        }

    } else {

        ElMessage.error('Please enter the amount of tokens to withdraw.');
        loading.close();

    }

}

export const claimRewardsForUsers = async (stakePoolId) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);

    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);
    const program = new anchor.Program(idl, REWARDS_CENTER_ADDRESS, provider);

    const pool_data = await fetchIdlAccountDataById(
        connection,
        [new PublicKey(stakePoolId)],
        REWARDS_CENTER_ADDRESS,
        idl
    )

    // Find Pubkey by seed
    const rewardDistributorId = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode("reward-distributor"),
            new PublicKey(stakePoolId).toBuffer(),
            new anchor.BN(0).toArrayLike(Buffer, "le", 8)
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const tx = new Transaction();

    // Claim for user
    let txs = [];
    const stakeEntries = await program.account.stakeEntry.all([
        {
            memcmp: {
                offset: 10,
                bytes: stakePoolId.toString(),
            },
        },
    ])

    const filtered_entries = stakeEntries.filter(
        (entry) =>
            entry.account.lastStaker.toString() !==
            PublicKey.default.toString()
    ).map((e) => {
        return { pubkey: e.publicKey, parsed: e.account }
    })

    const batchStakeEntries = chunkArray(filtered_entries, LOOKUP_BATCH_SIZE);

    for (const entries of batchStakeEntries) {

        const batchTxs = await claimRewards(
            connection,
            wallet,
            pool_data[Object.keys(pool_data)[0]].parsed.identifier.toString(),
            entries.map((entry) => {
                return {
                    mintId: entry.parsed.stakeMint,
                }
            }),
            [rewardDistributorId],
            true
        )

        txs = [...txs, ...batchTxs]

    }

    const batchedTxs = chunkArray(txs, batchSize);

    const final_batchedTxs = batchedTxs.map((txs) => {
        const transaction = new Transaction()
        transaction.instructions = txs.map((tx) => tx.instructions).flat()
        return transaction
    })

    tx.add(final_batchedTxs[0]);

    if (tx.instructions.length > 0) {
        const confirmOptions = { skipPreflight: false }
        await executeTransaction(provider.connection, tx, provider.wallet.wallet.value, { confirmOptions });
        // 更新完成後，重新整理
        window.location.reload();
    }

}

async function downloadSnapshot(data) {
    let body = `Total Staked Tokens,${data.length
        }\nSnapshot Timestamp,${Math.floor(
            Date.now() / 1000
        )}\n\nMint Address,Staker Address,Total Stake Seconds,Last Staked At,\n`
    data.forEach((data) => {
        body += `${data.parsed.stakeMint.toString()},${data.parsed.lastStaker.toString()},${data.parsed.totalStakeSeconds.toString()},${data.parsed.lastStakedAt.toString()}\n`
    })
    const element = document.createElement('a')
    element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(body)
    )
    element.setAttribute('download', 'snapshot.csv')

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
}

async function hacopayment(payer, connection, wallet) {

    const provider = new anchor.AnchorProvider(connection, wallet)
    const paymentInfoId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode("payment-info"),
            utils.bytes.utf8.encode(`TTGG`),// identifier
        ],
        REWARDS_CENTER_ADDRESS
    )[0];
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);
    const stakePaymentInfoData = await fetchIdlAccount(
        connection,
        paymentInfoId,
        "PaymentInfo",
        idl
    );

    const remainingAccounts = [
        {
            pubkey: stakePaymentInfoData.pubkey,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: payer,
            isSigner: true,
            isWritable: true,
        },
        {
            pubkey: SystemProgram.programId,
            isSigner: false,
            isWritable: false,
        },
        {// target
            pubkey: stakePaymentInfoData.parsed.paymentShares[0].address,
            isSigner: false,
            isWritable: true,
        }
    ];

    return remainingAccounts;
}

async function claimRewards(connection, wallet, stakePoolIdentifier, mintIds, rewardDistributorIds, claimingRewardsForUsers) {

    const provider = new anchor.AnchorProvider(connection, wallet)
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);
    const program = new anchor.Program(idl, REWARDS_CENTER_ADDRESS, provider);
    const isFungible = false;
    const stakePoolId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('stake-pool'),// STAKE_POOL_PREFIX.as_bytes()
            utils.bytes.utf8.encode(stakePoolIdentifier), // ix.identifier.as_ref()
        ],
        REWARDS_CENTER_ADDRESS // REWARDS_CENTER_ADDRESS
    )[0];
    const txs = [];

    //建立mints變量包含mintId、stakeEntryId、rewardEntryIds後續使用
    const mints = mintIds.map(
        ({ mintId }) => {
            const stakeEntryId = PublicKey.findProgramAddressSync(
                [
                    utils.bytes.utf8.encode("stake-entry"),
                    stakePoolId.toBuffer(),
                    mintId.toBuffer(),
                    wallet.publicKey && isFungible ? wallet.publicKey.toBuffer() : PublicKey.default.toBuffer(),
                ],
                REWARDS_CENTER_ADDRESS
            )[0];
            return {
                mintId,
                stakeEntryId,
                rewardEntryIds: rewardDistributorIds?.map((rewardDistributorId) =>
                    PublicKey.findProgramAddressSync(
                        [
                            utils.bytes.utf8.encode("reward-entry"),
                            rewardDistributorId.toBuffer(),
                            stakeEntryId.toBuffer(),
                        ],
                        REWARDS_CENTER_ADDRESS
                    )[0]
                ),
            };
        });

    //這個函數可從id ( PublicKey.findProgramAddressSync的返回都表示為id ) 得出該資料，這裡一次獲取多個
    let accountDataById = await fetchIdlAccountDataById(
        connection,
        [...(rewardDistributorIds ?? []),
        ...mints.map((m) => m.rewardEntryIds ?? []).flat(),
        ...(claimingRewardsForUsers
            ? mints.map((m) => PublicKey.findProgramAddressSync(
                [
                    utils.bytes.utf8.encode("stake-entry"),
                    stakePoolId.toBuffer(),
                    m.mintId.toBuffer(),
                    PublicKey.default.toBuffer()
                ],
                REWARDS_CENTER_ADDRESS
            )[0]).flat()
            : []),
        ],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    //從上述accountDataById獲取payment資料
    const claimRewardsPaymentInfoIds = rewardDistributorIds?.map((id) => {
        const rewardDistributorData = accountDataById[id.toString()];
        if (
            rewardDistributorData &&
            rewardDistributorData.type === "rewardDistributor"
        ) {
            return rewardDistributorData.parsed.claimRewardsPaymentInfo;
        }
        return null;
    });

    //第二段獲取id資料，在下一段程式碼對第一段accountDataById進行合併
    const accountDataById2 = await fetchIdlAccountDataById(connection, [
        ...(claimRewardsPaymentInfoIds ?? []),
    ]);
    accountDataById = { ...accountDataById, ...accountDataById2 };

    //對第一段建立的mints進行transaction實作
    for (const { stakeEntryId, rewardEntryIds } of mints) {
        const tx = new Transaction();
        if (
            rewardEntryIds &&
            rewardDistributorIds &&
            rewardDistributorIds?.length > 0
        ) {
            //1. 更新總staking時長
            const ix = await program
                .methods.updateTotalStakeSeconds()
                .accounts({
                    stakeEntry: stakeEntryId,
                    updater: wallet.publicKey,
                })
                .instruction();
            tx.add(ix);

            // 這裡判斷獎勵分配，必須先進行初始化然後在這才能順利執行，這段在unstake有一樣的寫法
            for (let j = 0; j < rewardDistributorIds.length; j++) {
                const rewardDistributorId = rewardDistributorIds[j];
                const rewardDistributorData =
                    accountDataById[rewardDistributorId.toString()];
                const rewardEntryId = rewardEntryIds[j];
                if (
                    rewardEntryId &&
                    rewardDistributorData &&
                    rewardDistributorData.type === "RewardDistributor"
                ) {

                    const rewardMint = rewardDistributorData.parsed.rewardMint;
                    const rewardEntry = accountDataById[rewardEntryId?.toString()];

                    const rewardDistributorTokenAccount = getAssociatedTokenAddressSync(
                        rewardMint,
                        rewardDistributorId,
                        true,
                    );

                    const stakeEntryDataInfo = accountDataById[stakeEntryId.toString()];

                    const userRewardMintTokenAccountOwnerId = stakeEntryDataInfo
                        ? stakeEntryDataInfo.parsed
                            .lastStaker
                        : wallet.publicKey;

                    const userRewardMintTokenAccount = await findAta(
                        rewardMint,
                        userRewardMintTokenAccountOwnerId,
                        true
                    );

                    tx.add(
                        createAssociatedTokenAccountIdempotentInstruction(
                            wallet.publicKey,
                            userRewardMintTokenAccount,
                            userRewardMintTokenAccountOwnerId,
                            rewardMint
                        )
                    );

                    //2. 第一次獲取獎勵沒有reward，需要初始化
                    if (!rewardEntry) {

                        const ix = await program
                            .methods.initRewardEntry()
                            .accounts({
                                rewardEntry: PublicKey.findProgramAddressSync(
                                    [
                                        utils.bytes.utf8.encode("reward-entry"),
                                        rewardDistributorId.toBuffer(),
                                        stakeEntryId.toBuffer(),
                                    ],
                                    REWARDS_CENTER_ADDRESS
                                )[0],
                                rewardDistributor: rewardDistributorId,
                                stakeEntry: stakeEntryId,
                                payer: wallet.publicKey,
                            })
                            .instruction();

                        tx.add(ix);

                    }

                    const remainingAccounts = await hacopayment(wallet.publicKey, connection, wallet)

                    //3. 獲取獎勵
                    const ix = await program
                        .methods.claimRewards()
                        .accounts({
                            rewardEntry: PublicKey.findProgramAddressSync(
                                [
                                    utils.bytes.utf8.encode("reward-entry"),
                                    rewardDistributorId.toBuffer(),
                                    stakeEntryId.toBuffer(),
                                ],
                                REWARDS_CENTER_ADDRESS
                            )[0],
                            rewardDistributor: rewardDistributorId,
                            stakeEntry: stakeEntryId,
                            stakePool: stakePoolId,
                            rewardMint: rewardMint,
                            userRewardMintTokenAccount: userRewardMintTokenAccount,
                            rewardDistributorTokenAccount: rewardDistributorTokenAccount,
                            user: wallet.publicKey,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: SystemProgram.programId
                        })
                        .remainingAccounts(remainingAccounts)
                        .instruction();
                    tx.add(ix);
                }
            }
        }
        txs.push(tx);
    }

    return txs;
}

export const snapshot = async (stakePoolId) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);

    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);
    const program = new anchor.Program(idl, REWARDS_CENTER_ADDRESS, provider);

    const stakeEntries = await program.account.stakeEntry.all([
        {
            memcmp: {
                offset: 10,
                bytes: stakePoolId.toString(),
            },
        },
    ])
    const filtered_entries = stakeEntries.filter(
        (entry) =>
            entry.account.lastStaker.toString() !==
            PublicKey.default.toString()
    )
        .map((e) => {
            return { pubkey: e.publicKey, parsed: e.account }
        })

    await downloadSnapshot(filtered_entries)

}

export const get_pool_default = async (stakePoolId) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);

    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);
    const pool_data = await fetchIdlAccountDataById(
        connection,
        [new PublicKey(stakePoolId)],
        REWARDS_CENTER_ADDRESS,
        idl
    )

    return pool_data[Object.keys(pool_data)[0]].parsed
}

export const get_distributor_default = async (stakePoolId) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);

    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const rewardDistributorId = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode("reward-distributor"),
            new PublicKey(stakePoolId).toBuffer(),
            new anchor.BN(0).toArrayLike(Buffer, "le", 8)
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const distributor_data = await fetchIdlAccountDataById(
        connection,
        [rewardDistributorId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    const result = distributor_data[Object.keys(distributor_data)[0]];

    const tx = new Transaction();
    const mint = result.parsed.rewardMint;

    const rewardDistributorAtaId = await withFindOrInitAssociatedTokenAccount(
        tx,
        connection,
        mint,
        rewardDistributorId,
        wallet.publicKey,
        true
    );

    let tokenDecimals = 0;

    try {

        const userMintAtaId = getAssociatedTokenAddressSync(
            mint,//rewardmintId,
            wallet.publicKey
        );

        const userMintInfo = await connection.getTokenAccountBalance(userMintAtaId);
        tokenDecimals = userMintInfo.value.decimals;

        const walletBalanced = Number(userMintInfo.value.amount) / (10 ** tokenDecimals);
        result.parsed.walletBalanced = walletBalanced;

    } catch {
        result.parsed.walletBalanced = 0;
    }

    try {
        const rewardDistributorAta = await getAccount(
            connection,
            rewardDistributorAtaId
        );

        const treasury_amount = Number(rewardDistributorAta.amount) / (10 ** tokenDecimals);
        result.parsed.treasury_amount = treasury_amount;
    } catch {
        result.parsed.treasury_amount = 0;
    }

    result.parsed.tokenDecimals = tokenDecimals;

    return result
}

export const get_config_default = async (stakePoolId) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const pool_data = await fetchIdlAccountDataById(
        connection,
        [new PublicKey(stakePoolId)],
        REWARDS_CENTER_ADDRESS,
        idl
    )

    const keyBuffer = Buffer.from(pool_data[Object.keys(pool_data)[0]].parsed.identifier, 'utf-8')//need to associate to the pool ientifier
    const prefixBuffer = Buffer.from('ss', 'utf-8')

    const configEntryId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode("config-entry"),
            prefixBuffer,
            keyBuffer
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const config_data = await fetchIdlAccountDataById(
        connection,
        [configEntryId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    if (config_data[Object.keys(config_data)[0]]) {

        // 這裡是stringn所以用正規表達式拆解所有dict然後獲取最後一次更新內容
        const latest_config_data = config_data[Object.keys(config_data)[0]].parsed.value.match(/\{[^}]*\}/g);
        const config = JSON.parse(latest_config_data[latest_config_data.length - 1]+"}");

        return config;

    } else {

        return {
            name: "",
            displayName: "",
            description: "",
            imageUrl: "",
            stakePoolAddress: "",
        }

    }

}

export const get_collection_default = async (stakePoolIdentifier) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const collectionMulId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('collection-mul'),
            utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const collectionMul = await fetchIdlAccountDataById(
        connection,
        [collectionMulId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    return collectionMul[Object.keys(collectionMul)[0]]?.parsed;

}

export const get_attribute_default = async (stakePoolIdentifier) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet);
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const attributeMulId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('attribute-mul'),
            utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const attributeMul = await fetchIdlAccountDataById(
        connection,
        [attributeMulId],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    return attributeMul[Object.keys(attributeMul)[0]]?.parsed;

}

export const full_config = async (idl, connection) => {

    const programAccounts = await connection.getProgramAccounts(
        REWARDS_CENTER_ADDRESS,
        {
            filters: [
                {
                    memcmp: {
                        offset: 0,
                        bytes: utils.bytes.bs58.encode(
                            BorshAccountsCoder.accountDiscriminator('ConfigEntry')
                        ),
                    },
                },
            ],
        }
    );
    const configDatas = [];
    const coder = new BorshAccountsCoder(idl);

    programAccounts.forEach((account) => {
        try {
            const entryData = coder.decode('ConfigEntry', account.account.data);
            if (entryData) {
                configDatas.push({
                    ...account,
                    parsed: entryData,
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-empty
        }
    });

    return configDatas
}