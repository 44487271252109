<template>
  <aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 "
    id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" href="/" target="_blank">
        <img src="./assets/img/logo-ct-dark.png" class="navbar-brand-img h-100" alt="main_logo">
        <span class="font-weight-bold" style="font-size: 20px; margin-left: 10px;">Mimir Lab</span>
      </a>
    </div>
    <hr class="horizontal dark mt-0">
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="/" :class="{ 'active': pathName == '/' }">
            <div
              class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="ni ni-app nav-side-icon" :style="pathName == '/' ? 'color: white' : 'color: black'"></i>
            </div>
            <span class="nav-link-text ms-1">Profile</span>
          </a>
        </li>
        <li class="nav-item">
          <a data-bs-toggle="collapse" href="#dashboardsExamples" class="nav-link" aria-controls="dashboardsExamples"
            role="button" aria-expanded="false" :class="{ 'active': pathName.includes('staking') }">
            <div
              class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center  me-2">
              <i class="ni ni-credit-card nav-side-icon"
                :style="pathName.includes('staking') ? 'color: white' : 'color: black'"></i>
            </div>
            <span class="nav-link-text ms-1">Staking Tools</span>
          </a>
          <div class="collapse  show " id="dashboardsExamples">
            <ul class="nav ms-4 ps-3">
              <li class="nav-item ">
                <a class="nav-link" href="/staking" :class="{ 'active': pathName == '/staking' }">
                  <span class="sidenav-mini-icon"> A </span>
                  <span class="sidenav-normal"> Gamified Staking </span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="sidenav-footer mx-3 ">
      <div class="card card-background shadow-none card-background-mask-secondary" id="sidenavCard">
        <div class="full-background bg-mimir"></div>
        <div class="card-body text-start p-3 w-100">
          <div
            class="icon icon-shape icon-sm bg-white shadow text-center mb-3 d-flex align-items-center justify-content-center border-radius-md">
            <img src="./assets/logo.png" class="w-100" style="border-radius: 10px;" />
          </div>
          <div class="docs-info">
            <h6 class="text-white up mb-0">Need help?</h6>
            <p class="text-xs font-weight-bold">Please check our docs</p>
            <a href="https://mimir-lab.gitbook.io/mimir-lab/mimir-gods-nft-the-genesis-collection" target="_blank" class="btn btn-white btn-sm w-100 mb-0">Documentation</a>
          </div>
        </div>
      </div>
    </div>
  </aside>
  <main class="main-content position-relative border-radius-lg h-100">
    <!-- Navbar -->
    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur"
      navbar-scroll="true">
      <div class="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <!-- <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Home</a></li>
            <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Profile</li>
          </ol>
          <h6 class="font-weight-bolder mb-0">Profile</h6> -->
        </nav>
        <wallet-multi-button></wallet-multi-button>
        <button 
          type="button" 
          class="btn bg-black text-white w-lg-15 w-auto mb-0 ms-3" 
          style="font-size: 16px; height: 48px; border-radius: 6px;"
          @click="updateStakingPool"
          v-if="pathName.includes('/staking/edit')"
        >
          Save
        </button>
        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <ul class="navbar-nav  justify-content-end">
            <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a href="javascript:;" class="nav-link text-body p-0" id="iconNavbarSidenav">
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
    <router-view />

    <div class="container-fluid py-4">
      <footer class="footer pt-3">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
              <div class="copyright text-center text-sm text-muted text-lg-start">
                Copyright © {{ thisYear }},
                <a>Mimir Lab</a> All rights reserved.
              </div>
            </div>
            <div class="col-lg-6">
              <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                <li class="nav-item">
                  <a href="https://www.mimirlab.xyz/" class="nav-link text-muted" target="_blank">Mimir Lab</a>
                </li>
                <li class="nav-item">
                  <a href="https://discord.gg/pYjd93XR2M" class="nav-link text-muted" target="_blank">Discord</a>
                </li>
                <li class="nav-item">
                  <a href="https://twitter.com/Mimirlab" class="nav-link text-muted" target="_blank">Twitter</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </main>
</template>

<script>
import { initWallet, WalletMultiButton } from 'solana-wallets-vue';
import { initWorkspace, initMetaplex, updateStakingPool } from '@/composables';
import {
  PhantomWalletAdapter
} from "@solana/wallet-adapter-wallets";
import { ref } from 'vue';

export default {
  name: 'App',
  components: {
    WalletMultiButton
  },
  setup() {

    const pathName = ref(window.location.pathname);
    const thisYear = new Date().getFullYear();

    const walletOptions = {
      wallets: [
        new PhantomWalletAdapter()
      ],
      autoConnect: true
    };

    initWallet(walletOptions);
    initWorkspace();
    initMetaplex();

    return {
      pathName,
      thisYear,
      updateStakingPool
    }

  },
  mounted() {

    const pathLength = this.pathName.split('/').length;
    const pathPrefix = '../'.repeat(pathLength - 1);

    const scriptList = [
      `${pathPrefix}js/soft-ui-dashboard.min.js`,
      `${pathPrefix}js/core/bootstrap.min.js`,
      `${pathPrefix}js/core/popper.min.js`
    ]

    scriptList.map((script) => {
      const scriptElement = document.createElement("script");
      scriptElement.setAttribute('src', script);
      document.head.appendChild(scriptElement);
    });
  }
}

</script>

<style>
iframe#webpack-dev-server-client-overlay{display:none!important}
</style>
