import {
    Transaction,
    PublicKey,
    SystemProgram,
    SYSVAR_INSTRUCTIONS_PUBKEY,
    ComputeBudgetProgram,
    TransactionMessage,
    VersionedTransaction,
    // AddressLookupTableProgram
} from '@solana/web3.js';
import {
    fetchIdlAccount,
    fetchIdlAccountDataById,
    findMintMetadataId,
    findAta,
    tryNull,
    findTokenRecordId,
    executeTransactions
} from "@cardinal/common";
import { findMintManagerId } from '@solana-nft-programs/creator-standard';
import { utils } from "@coral-xyz/anchor";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { PROGRAM_ID as TOKEN_AUTH_RULES_ID } from "@metaplex-foundation/mpl-token-auth-rules";
import { getAssociatedTokenAddressSync, TOKEN_PROGRAM_ID, createAssociatedTokenAccountIdempotentInstruction, getMint } from "@solana/spl-token";//for staking 
import { AnchorProvider, Program } from "@project-serum/anchor";
import { useWorkspace } from './useWorkspace';
import { useCommonStore } from '@/store';
import { Metaplex } from '@metaplex-foundation/js';

import dotenv from 'dotenv';
import { ElLoading, ElMessage } from 'element-plus';
import { upsertClaimTime } from './NonCustodialPools';
dotenv.config();

const anchor = require('@project-serum/anchor');

const hacoIdentifier = process.env.VUE_APP_HACO_IDENTIFIER;//this is for the owner
const REWARDS_CENTER_ADDRESS = new PublicKey(process.env.VUE_APP_REWARDS_CENTER_ADDRESS);
const METADATA_PROGRAM_ID = new PublicKey(process.env.VUE_APP_METADATA_PROGRAM);

async function hacopayment(payer, connection, wallet) {

    const provider = new AnchorProvider(connection, wallet);

    const paymentInfoId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode("payment-info"),
            utils.bytes.utf8.encode(hacoIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const idl = await Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const stakePaymentInfoData = await fetchIdlAccount(
        connection,
        paymentInfoId,
        "PaymentInfo",
        idl
    );

    const remainingAccounts = [
        {
            pubkey: stakePaymentInfoData.pubkey,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: payer,
            isSigner: true,
            isWritable: true,
        },
        {
            pubkey: SystemProgram.programId,
            isSigner: false,
            isWritable: false,
        },
        {// target
            pubkey: stakePaymentInfoData.parsed.paymentShares[0].address,
            isSigner: false,
            isWritable: true,
        }
    ];

    return remainingAccounts;
}

export const unstake = async (stakePoolIdentifier, mintIds, rewardDistributorIds) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet)
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const program = new Program(idl, REWARDS_CENTER_ADDRESS, provider);
    const stakePoolId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('stake-pool'),// STAKE_POOL_PREFIX.as_bytes()
            utils.bytes.utf8.encode(stakePoolIdentifier), // ix.identifier.as_ref()
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const txs = [];

    //同上claimreward函數內容mints的建立方式
    const mints = mintIds.map(({ mintId }) => {

        const stakeEntryId = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("stake-entry"),
                stakePoolId.toBuffer(),
                mintId.toBuffer(),
                PublicKey.default.toBuffer(),
            ],
            REWARDS_CENTER_ADDRESS
        )[0];

        return {
            mintId,
            stakeEntryId,
            rewardEntryIds: rewardDistributorIds?.map((rewardDistributorId) =>
                PublicKey.findProgramAddressSync(
                    [
                        utils.bytes.utf8.encode("reward-entry"),
                        rewardDistributorId.toBuffer(),
                        stakeEntryId.toBuffer(),
                    ],
                    REWARDS_CENTER_ADDRESS
                )[0]
            ),
        };

    });

    let accountDataById = await fetchIdlAccountDataById(
        connection,
        [
            stakePoolId,
            ...(rewardDistributorIds ?? []),
            ...mints.map((m) => m.rewardEntryIds ?? []).flat(),
            ...mints.map((m) => findMintManagerId(m.mintId)),
            ...mints.map((m) => m.stakeEntryId),
        ],
        //需要明確指定是哪個program以及idl
        REWARDS_CENTER_ADDRESS,
        idl
    );

    const stakePoolData = accountDataById[stakePoolId.toString()];
    if (!stakePoolData?.parsed || stakePoolData.type !== "StakePool") {
        throw "Stake pool not found";
    }

    const claimRewardsPaymentInfoIds = rewardDistributorIds?.map((id) => {
        const rewardDistributorData = accountDataById[id.toString()];
        console.log(rewardDistributorData);
        if (
            rewardDistributorData &&
            rewardDistributorData.type === "RewardDistributor"
        ) {
            return rewardDistributorData.parsed.claimRewardsPaymentInfo;
        }
        return null;
    });

    const accountDataById2 = await fetchIdlAccountDataById(
        connection,
        [
            stakePoolData.parsed.unstakePaymentInfo,
            ...(claimRewardsPaymentInfoIds ?? []),
        ],
        //需要明確指定是哪個program以及idl
        REWARDS_CENTER_ADDRESS,
        idl
    );

    accountDataById = { ...accountDataById, ...accountDataById2 };

    for (const { mintId, stakeEntryId, rewardEntryIds } of mints) {

        const tx = new Transaction();

        const metadataId = findMintMetadataId(mintId); //v2
        const nftmint = await getMint(connection, mintId);

        const userattribute = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode('user-attribute'),
                anchor.utils.bytes.utf8.encode(stakePoolIdentifier),
                nftmint.address.toBuffer()
            ],
            REWARDS_CENTER_ADDRESS
        )[0];

        const userEscrowId = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("escrow"),
                wallet.publicKey.toBuffer()
            ],
            REWARDS_CENTER_ADDRESS
        )[0];

        const userAtaId = getAssociatedTokenAddressSync(mintId, wallet.publicKey);
        const stakeEntry = accountDataById[stakeEntryId.toString()];

        if (
            rewardEntryIds &&
            rewardDistributorIds &&
            rewardDistributorIds.length > 0 &&
            !(
                stakeEntry?.type === "StakeEntry" &&
                stakeEntry.parsed.cooldownStartSeconds
            )
        ) {

            //新增stake總時長
            const stake_seconds_ix = await program
                .methods.updateTotalStakeSeconds()
                .accounts({
                    stakeEntry: stakeEntryId,
                    updater: wallet.publicKey,
                })
                .instruction();

            tx.add(stake_seconds_ix);

            //由於輸入的reward可包含多個公鑰，這裡會各別處理，但是正常來說只會有一個因為不會跨池(rewardDistributorIds的變量是由stakePoolId及program判斷的)
            for (let j = 0; j < rewardDistributorIds.length; j++) {

                const rewardDistributorId = rewardDistributorIds[j];
                const rewardDistributorData = accountDataById[rewardDistributorId.toString()];
                const rewardEntryId = rewardEntryIds[j];

                if (
                    rewardEntryId &&
                    rewardDistributorData &&
                    rewardDistributorData.type === "RewardDistributor"
                ) {

                    const rewardMint = rewardDistributorData.parsed.rewardMint;
                    const rewardEntry = accountDataById[rewardEntryId?.toString()];

                    //在transfer時所需的mint & owner ATA 以及 mint & receiver ATA 
                    const rewardDistributorTokenAccount = getAssociatedTokenAddressSync(
                        rewardMint,
                        rewardDistributorId,
                        true
                    );

                    const stakeEntryDataInfo = accountDataById[stakeEntryId.toString()];

                    const userRewardMintTokenAccountOwnerId = stakeEntryDataInfo
                        ? stakeEntryDataInfo.parsed
                            .lastStaker
                        : wallet.publicKey;

                    const userRewardMintTokenAccount = await findAta(
                        rewardMint,
                        userRewardMintTokenAccountOwnerId,
                        true
                    );

                    tx.add(
                        createAssociatedTokenAccountIdempotentInstruction(
                            wallet.publicKey,
                            userRewardMintTokenAccount,
                            userRewardMintTokenAccountOwnerId,
                            rewardMint
                        )
                    );

                    //unstake同時包含claim reward所以還是需要判斷entry是否已經初始化
                    if (!rewardEntry) {

                        const reward_entry_ix = await program
                            .methods.initRewardEntry()
                            .accounts({
                                rewardEntry: PublicKey.findProgramAddressSync(
                                    [
                                        utils.bytes.utf8.encode("reward-entry"),
                                        rewardDistributorId.toBuffer(),
                                        stakeEntryId.toBuffer(),
                                    ],
                                    REWARDS_CENTER_ADDRESS
                                )[0],
                                rewardDistributor: rewardDistributorId,
                                stakeEntry: stakeEntryId,
                                payer: wallet.publicKey,
                            })
                            .instruction();

                        console.log('reward_entry_ix', reward_entry_ix)

                        tx.add(reward_entry_ix);

                    }

                    const collectionMulId = PublicKey.findProgramAddressSync(
                        [
                            utils.bytes.utf8.encode('collection-mul'),
                            utils.bytes.utf8.encode(stakePoolIdentifier),
                        ],
                        REWARDS_CENTER_ADDRESS
                    )[0];

                    const attributeMulId = PublicKey.findProgramAddressSync(
                        [
                            utils.bytes.utf8.encode('attribute-mul'),
                            utils.bytes.utf8.encode(stakePoolIdentifier),
                        ],
                        REWARDS_CENTER_ADDRESS
                    )[0];

                    const remainingAccounts = await hacopayment(wallet.publicKey, connection, wallet)

                    const metadata_remaining = {
                        pubkey: metadataId,
                        isSigner: false,
                        isWritable: true
                    }

                    const collection_mul_remaining = {
                        pubkey: collectionMulId,
                        isSigner: false,
                        isWritable: false
                    }

                    const attribute_mul_remaining = {
                        pubkey: attributeMulId,
                        isSigner: false,
                        isWritable: false
                    }

                    const user_attribute_remaining = {
                        pubkey: userattribute,
                        isSigner: false,
                        isWritable: false
                    }

                    remainingAccounts.unshift(
                        metadata_remaining,
                        collection_mul_remaining,
                        attribute_mul_remaining,
                        user_attribute_remaining,
                    );

                    const claim_rewards_ix = await program
                        .methods.claimRewards()
                        .accounts({
                            rewardEntry: PublicKey.findProgramAddressSync(
                                [
                                    utils.bytes.utf8.encode("reward-entry"),
                                    rewardDistributorId.toBuffer(),
                                    stakeEntryId.toBuffer(),
                                ],
                                REWARDS_CENTER_ADDRESS
                            )[0],
                            rewardDistributor: rewardDistributorId,
                            stakeEntry: stakeEntryId,
                            stakePool: stakePoolId,
                            rewardMint: rewardMint,
                            userRewardMintTokenAccount: userRewardMintTokenAccount,
                            rewardDistributorTokenAccount: rewardDistributorTokenAccount,
                            user: wallet.publicKey,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: SystemProgram.programId
                        })
                        .remainingAccounts(remainingAccounts)
                        .instruction();

                    tx.add(claim_rewards_ix);

                }
            }
        }

        const remainingAccounts = await hacopayment(wallet.publicKey, connection, wallet)

        const metadata = await tryNull(
            Metadata.fromAccountAddress(connection, metadataId)
        );

        //unstake從這裡開始寫
        const METADATA_PROGRAM_ID = new PublicKey(process.env.VUE_APP_METADATA_PROGRAM);

        const editionId = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("metadata"),
                METADATA_PROGRAM_ID.toBuffer(),
                mintId.toBuffer(),
                utils.bytes.utf8.encode("edition"),
            ],
            METADATA_PROGRAM_ID
        )[0];

        const stakeTokenRecordAccountId = findTokenRecordId(mintId, userAtaId);

        tx.add(
            ComputeBudgetProgram.setComputeUnitLimit({
                units: 100000000,
            })
        );
        if (metadata?.programmableConfig) {

            const unstakeIx = await program
                .methods.unstakePnft()
                .accountsStrict({
                    stakePool: stakePoolId,
                    stakeEntry: stakeEntryId,
                    stakeMint: mintId,
                    stakeMintMetadata: metadataId,
                    stakeMintEdition: editionId,
                    stakeTokenRecordAccount: stakeTokenRecordAccountId,
                    authorizationRules:
                        metadata?.programmableConfig?.ruleSet ?? METADATA_PROGRAM_ID,
                    user: wallet.publicKey,
                    userEscrow: userEscrowId,
                    userStakeMintTokenAccount: userAtaId,
                    tokenMetadataProgram: METADATA_PROGRAM_ID,
                    sysvarInstructions: SYSVAR_INSTRUCTIONS_PUBKEY,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                    authorizationRulesProgram: TOKEN_AUTH_RULES_ID,
                })
                .remainingAccounts(remainingAccounts)
                .instruction();

            tx.add(unstakeIx);

        } else {

            const unstakeIx = await program
                .methods.unstakeEdition()
                .accounts({
                    stakeEntry: stakeEntryId,
                    stakePool: stakePoolId,
                    stakeMint: mintId,
                    stakeMintEdition: editionId,
                    user: wallet.publicKey,
                    userEscrow: userEscrowId,
                    userStakeMintTokenAccount: userAtaId,
                    tokenMetadataProgram: METADATA_PROGRAM_ID,
                })
                .remainingAccounts(remainingAccounts)
                .instruction();

            tx.add(unstakeIx);

        }

        txs.push(tx);

    }

    const result = await executeTransactions(
        connection,
        txs,
        wallet
    );

    if (result.length == 1 && result[0] == null) {

        ElMessage({
            message: 'Pool have no rewards to claim.',
            type: 'error',
            duration: 1500,
            showClose: true
        });
        ElLoading.service().close();
        return;

    } else {

        ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
        await new Promise((resolve) => setTimeout(resolve, 10000));
        window.location.reload();

    }

}

export const stake = async (stakePoolIdentifier, mintIds) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet)
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const lookupTableAddress = new PublicKey("Fc8xdFNVTZz3n9acrRivHtjanNKifXDBF67AVL8VxUtn")
    const lookupTableAccount = (await connection.getAddressLookupTable(lookupTableAddress));
    let latestBlockhash = await connection.getLatestBlockhash('finalized');

    const program = new Program(idl, REWARDS_CENTER_ADDRESS, provider);
    const stakePoolId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('stake-pool'),
            utils.bytes.utf8.encode(stakePoolIdentifier),
        ],
        REWARDS_CENTER_ADDRESS
    )[0];

    const txs = [];

    //建立mints包含所需資料的公鑰
    const mints = mintIds.map(
        ({ mintId }) => {
            return {
                mintId,
                stakeEntryId: PublicKey.findProgramAddressSync(
                    [
                        utils.bytes.utf8.encode("stake-entry"),
                        stakePoolId.toBuffer(),
                        mintId.toBuffer(),
                        PublicKey.default.toBuffer(),
                    ],
                    REWARDS_CENTER_ADDRESS
                )[0],
                mintTokenAccountId: getAssociatedTokenAddressSync(mintId, wallet.publicKey, true),
            };
        }
    );

    //建立accountDataById，包含上述建立mints裡id的所有資料
    const accountDataById = await fetchIdlAccountDataById(connection, [
        stakePoolId,
        ...mints.map((m) => m.stakeEntryId),
        ...mints.map((m) => findMintMetadataId(m.mintId)),
    ],
        //範例沒有這兩行但這裡需要明確表示
        REWARDS_CENTER_ADDRESS,
        idl
    );

    //判斷accountDataById裡獲取資料的內容，先是確認有池的存在
    const stakePoolData = accountDataById[stakePoolId.toString()];
    if (!stakePoolData?.parsed || stakePoolData.type !== "StakePool") {
        throw "Stake pool not found";
    }

    //開始對上面mints裡的所有資料進行判斷，皆為獲取stake pnft所需要的公鑰
    for (const { mintId, mintTokenAccountId, stakeEntryId } of mints) {

        const metaplex = Metaplex.make(connection);

        const nftmetadata = await metaplex.nfts().findByMint({ mintAddress: mintId });

        const attribute = nftmetadata.json?.attributes.map(item => [item.trait_type, item.value]);

        const nftmint = await getMint(connection, mintId);

        const userattribute = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode('user-attribute'),
                anchor.utils.bytes.utf8.encode(stakePoolIdentifier),
                nftmint.address.toBuffer()
            ],
            REWARDS_CENTER_ADDRESS
        )[0];

        const userattributeData = await fetchIdlAccountDataById(
            connection,
            [userattribute],
            REWARDS_CENTER_ADDRESS,
            idl
        );

        const tx = new Transaction();

        if (!userattributeData[Object.keys(userattributeData)[0]] && nftmetadata.collection?.address) {

            const userattribute_ix = await program.methods
                .initUserAttribute({
                    collection: nftmetadata.collection?.address,
                    attribute: attribute,
                    identifier: stakePoolIdentifier,
                    authority: provider.wallet.publicKey,
                })
                .accounts({
                    userAttribute: userattribute,
                    userMint: nftmint.address,
                    payer: provider.wallet.publicKey,
                    systemProgram: SystemProgram.programId
                })
                .instruction();

            tx.add(userattribute_ix)

        }

        const metadataId = findMintMetadataId(mintId);
        const metadataAccountInfo = accountDataById[metadataId.toString()];
        const metadataInfo = metadataAccountInfo
            ? Metadata.fromAccountInfo(metadataAccountInfo)[0]
            : undefined;

        //第一次stake需要init entry
        if (!accountDataById[stakeEntryId.toString()]) {

            const ix = await program
                .methods.initEntry(wallet.publicKey)
                .accounts({
                    stakeEntry: stakeEntryId,
                    stakePool: stakePoolId,
                    stakeMint: mintId,
                    stakeMintMetadata: metadataId,
                    payer: wallet.publicKey,
                    systemProgram: SystemProgram.programId,
                })
                .instruction();

            tx.add(ix);

        }

        const userEscrowId = PublicKey.findProgramAddressSync(
            [utils.bytes.utf8.encode("escrow"), wallet.publicKey.toBuffer()],
            REWARDS_CENTER_ADDRESS
        )[0];

        const remainingAccounts = await hacopayment(wallet.publicKey, connection, wallet);

        console.log(remainingAccounts);

        const editionId = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("metadata"),
                METADATA_PROGRAM_ID.toBuffer(),
                mintId.toBuffer(),
                utils.bytes.utf8.encode("edition"),
            ],
            METADATA_PROGRAM_ID
        )[0];

        const stakeTokenRecordAccountId = findTokenRecordId(
            mintId,
            mintTokenAccountId
        );

        if (metadataInfo && metadataInfo.programmableConfig) {

            tx.add(
                ComputeBudgetProgram.setComputeUnitLimit({
                    units: 100000000,
                })
            );
            const stakeIx = await program
                .methods.stakePnft()
                .accountsStrict({
                    stakePool: stakePoolId,
                    stakeEntry: stakeEntryId,
                    stakeMint: mintId,
                    stakeMintMetadata: metadataId,
                    stakeMintEdition: editionId,
                    stakeTokenRecordAccount: stakeTokenRecordAccountId,
                    authorizationRules:
                        metadataInfo?.programmableConfig?.ruleSet ?? METADATA_PROGRAM_ID,
                    user: wallet.publicKey,
                    userEscrow: userEscrowId,
                    userStakeMintTokenAccount: mintTokenAccountId,
                    tokenMetadataProgram: METADATA_PROGRAM_ID,
                    sysvarInstructions: SYSVAR_INSTRUCTIONS_PUBKEY,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                    authorizationRulesProgram: TOKEN_AUTH_RULES_ID,
                })
                .remainingAccounts(remainingAccounts)
                .instruction();

            tx.add(stakeIx);

        } else {

            const stakeIx = await program
                .methods.stakeEdition(new anchor.BN(1))
                .accounts({
                    stakePool: stakePoolId,
                    stakeEntry: stakeEntryId,
                    stakeMint: mintId,
                    stakeMintEdition: editionId,
                    stakeMintMetadata: metadataId,
                    user: wallet.publicKey,
                    userEscrow: userEscrowId,
                    userStakeMintTokenAccount: mintTokenAccountId,
                    tokenMetadataProgram: METADATA_PROGRAM_ID,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                })
                .remainingAccounts(remainingAccounts)
                .instruction();

            tx.add(stakeIx);

        }
        txs.push(tx);

    }
    console.log(txs)

    const messageWithLookupTable = new TransactionMessage({
        payerKey: wallet.publicKey,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: txs[0].instructions
    }).compileToV0Message([lookupTableAccount.value]);

    const transactionWithLookupTable = new VersionedTransaction(messageWithLookupTable);
    // await executeTransactions(connection, txs, wallet);
    await executeTransactions(connection, [transactionWithLookupTable], wallet);

}

export const claimRewards = async (stakePoolIdentifier, mintIds, rewardDistributorIds, claimingRewardsForUsers, lastClaimTime) => {

    const { wallet } = useWorkspace();
    wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
    wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new anchor.web3.Connection(clusterUrl);
    const provider = new anchor.AnchorProvider(connection, wallet)
    const idl = await anchor.Program.fetchIdl(REWARDS_CENTER_ADDRESS, provider);

    const program = new Program(idl, REWARDS_CENTER_ADDRESS, provider);
    const isFungible = false;
    const stakePoolId = PublicKey.findProgramAddressSync(
        [
            utils.bytes.utf8.encode('stake-pool'),// STAKE_POOL_PREFIX.as_bytes()
            utils.bytes.utf8.encode(stakePoolIdentifier), // ix.identifier.as_ref()
        ],
        REWARDS_CENTER_ADDRESS // REWARDS_CENTER_ADDRESS
    )[0];

    const txs = [];

    //建立mints變量包含mintId、stakeEntryId、rewardEntryIds後續使用
    const mints = mintIds.map(
        ({ mintId }) => {
            const stakeEntryId = PublicKey.findProgramAddressSync(
                [
                    utils.bytes.utf8.encode("stake-entry"),
                    stakePoolId.toBuffer(),
                    mintId.toBuffer(),
                    wallet.publicKey && isFungible ? wallet.publicKey.toBuffer() : PublicKey.default.toBuffer(),
                ],
                REWARDS_CENTER_ADDRESS
            )[0];
            return {
                mintId,
                stakeEntryId,
                rewardEntryIds: rewardDistributorIds?.map((rewardDistributorId) =>
                    PublicKey.findProgramAddressSync(
                        [
                            utils.bytes.utf8.encode("reward-entry"),
                            rewardDistributorId.toBuffer(),
                            stakeEntryId.toBuffer(),
                        ],
                        REWARDS_CENTER_ADDRESS
                    )[0]
                ),
            };
        });

    //這個函數可從id ( PublicKey.findProgramAddressSync的返回都表示為id ) 得出該資料，這裡一次獲取多個
    let accountDataById = await fetchIdlAccountDataById(
        connection,
        [...(rewardDistributorIds ?? []),
        ...mints.map((m) => m.rewardEntryIds ?? []).flat(),
        ...(claimingRewardsForUsers
            ? mints.map((m) => PublicKey.findProgramAddressSync(
                [
                    utils.bytes.utf8.encode("stake-entry"),
                    stakePoolId.toBuffer(),
                    m.mintId.toBuffer(),
                    PublicKey.default.toBuffer()
                ],
                REWARDS_CENTER_ADDRESS
            )[0]).flat()
            : []),
        ],
        REWARDS_CENTER_ADDRESS,
        idl
    );

    //從上述accountDataById獲取payment資料
    const claimRewardsPaymentInfoIds = rewardDistributorIds?.map((id) => {
        const rewardDistributorData = accountDataById[id.toString()];
        if (
            rewardDistributorData &&
            rewardDistributorData.type === "rewardDistributor"
        ) {
            return rewardDistributorData.parsed.claimRewardsPaymentInfo;
        }
        return null;
    });

    //第二段獲取id資料，在下一段程式碼對第一段accountDataById進行合併
    const accountDataById2 = await fetchIdlAccountDataById(connection, [
        ...(claimRewardsPaymentInfoIds ?? []),
    ]);
    accountDataById = { ...accountDataById, ...accountDataById2 };

    //對第一段建立的mints進行transaction實作
    for (const { mintId, stakeEntryId, rewardEntryIds } of mints) {

        const metadataId = findMintMetadataId(mintId); //v2

        const nftmint = await getMint(connection, mintId)
        const userattribute = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode('user-attribute'),
                anchor.utils.bytes.utf8.encode(stakePoolIdentifier),
                nftmint.address.toBuffer()
            ],
            REWARDS_CENTER_ADDRESS
        )[0];

        const tx = new Transaction();
        if (
            rewardEntryIds &&
            rewardDistributorIds &&
            rewardDistributorIds?.length > 0
        ) {
            //1. 更新總staking時長
            const ix = await program
                .methods.updateTotalStakeSeconds()
                .accounts({
                    stakeEntry: stakeEntryId,
                    updater: wallet.publicKey,
                })
                .instruction();
            tx.add(ix);

            // 這裡判斷獎勵分配，必須先進行初始化然後在這才能順利執行，這段在unstake有一樣的寫法
            for (let j = 0; j < rewardDistributorIds.length; j++) {

                const rewardDistributorId = rewardDistributorIds[j];

                const rewardDistributorData =
                    accountDataById[rewardDistributorId.toString()];

                const rewardEntryId = rewardEntryIds[j];

                if (
                    rewardEntryId &&
                    rewardDistributorData &&
                    rewardDistributorData.type === "RewardDistributor"
                ) {

                    const rewardMint = rewardDistributorData.parsed.rewardMint;
                    const rewardEntry = accountDataById[rewardEntryId?.toString()];

                    const rewardDistributorTokenAccount = getAssociatedTokenAddressSync(
                        rewardMint,
                        rewardDistributorId,
                        true,
                    );

                    const stakeEntryDataInfo = accountDataById[stakeEntryId.toString()];

                    const userRewardMintTokenAccountOwnerId = stakeEntryDataInfo
                        ? stakeEntryDataInfo.parsed
                            .lastStaker
                        : wallet.publicKey;

                    const userRewardMintTokenAccount = await findAta(
                        rewardMint,
                        userRewardMintTokenAccountOwnerId,
                        true
                    );

                    tx.add(
                        createAssociatedTokenAccountIdempotentInstruction(
                            wallet.publicKey,
                            userRewardMintTokenAccount,
                            userRewardMintTokenAccountOwnerId,
                            rewardMint
                        )
                    );

                    //2. 第一次獲取獎勵沒有reward，需要初始化
                    if (!rewardEntry) {

                        const reward_entry_ix = await program
                            .methods.initRewardEntry()
                            .accounts({
                                rewardEntry: PublicKey.findProgramAddressSync(
                                    [
                                        utils.bytes.utf8.encode("reward-entry"),
                                        rewardDistributorId.toBuffer(),
                                        stakeEntryId.toBuffer(),
                                    ],
                                    REWARDS_CENTER_ADDRESS
                                )[0],
                                rewardDistributor: rewardDistributorId,
                                stakeEntry: stakeEntryId,
                                payer: wallet.publicKey,
                            })
                            .instruction();

                        tx.add(reward_entry_ix);

                    }

                    const collectionMulId = PublicKey.findProgramAddressSync(
                        [
                            utils.bytes.utf8.encode('collection-mul'),
                            utils.bytes.utf8.encode(stakePoolIdentifier),
                        ],
                        REWARDS_CENTER_ADDRESS
                    )[0];

                    const attributeMulId = PublicKey.findProgramAddressSync(
                        [
                            utils.bytes.utf8.encode('attribute-mul'),
                            utils.bytes.utf8.encode(stakePoolIdentifier),
                        ],
                        REWARDS_CENTER_ADDRESS
                    )[0];

                    const remainingAccounts = await hacopayment(wallet.publicKey, connection, wallet);

                    const metadata_remaining = {
                        pubkey: metadataId,
                        isSigner: false,
                        isWritable: true
                    }

                    const collection_mul_remaining = {
                        pubkey: collectionMulId,
                        isSigner: false,
                        isWritable: false
                    }

                    const attribute_mul_remaining = {
                        pubkey: attributeMulId,
                        isSigner: false,
                        isWritable: false
                    }

                    const user_attribute_remaining = {
                        pubkey: userattribute,
                        isSigner: false,
                        isWritable: false
                    }

                    remainingAccounts.unshift(
                        metadata_remaining,
                        collection_mul_remaining,
                        attribute_mul_remaining,
                        user_attribute_remaining,
                    );

                    //3. 獲取獎勵
                    const claim_rewards_ix = await program
                        .methods.claimRewards()
                        .accounts({
                            rewardEntry: PublicKey.findProgramAddressSync(
                                [
                                    utils.bytes.utf8.encode("reward-entry"),
                                    rewardDistributorId.toBuffer(),
                                    stakeEntryId.toBuffer(),
                                ],
                                REWARDS_CENTER_ADDRESS
                            )[0],
                            rewardDistributor: rewardDistributorId,
                            stakeEntry: stakeEntryId,
                            stakePool: stakePoolId,
                            rewardMint: rewardMint,
                            userRewardMintTokenAccount: userRewardMintTokenAccount,
                            rewardDistributorTokenAccount: rewardDistributorTokenAccount,
                            user: wallet.publicKey,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: SystemProgram.programId
                        })
                        .remainingAccounts(remainingAccounts)
                        .instruction();

                    tx.add(claim_rewards_ix);

                }
            }
        }

        txs.push(tx);

    }

    try {

        const result = await executeTransactions(
            connection,
            txs,
            wallet
        );

        if (result.length == 1 && result[0] == null) {

            ElMessage({
                message: 'Pool have no rewards to claim.',
                type: 'error',
                duration: 1500,
                showClose: true
            });
            ElLoading.service().close();
            return;

        } else {

            await upsertClaimTime(stakePoolId.toBase58());
            lastClaimTime.value = new Date().getTime();
            ElLoading.service().setText('Please wait for few seconds and do not refresh the page.');
            await new Promise((resolve) => setTimeout(resolve, 10000));
            window.location.reload();

        }

    } catch (error) {
        const errorMessage = error.message;
        if (
            errorMessage.includes('User rejected the request.') ||
            errorMessage.includes('failed to send transaction: Transaction simulation failed: Blockhash not found')
        ) {
            await upsertClaimTime(stakePoolId.toBase58());
            lastClaimTime.value = new Date().getTime();
        }
        console.log(error.message);
        ElLoading.service().close();
        return;
    }

}


// export const lookUpTable = async () => {

//     const { wallet } = useWorkspace();
//     wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
//     wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

//     const commonStore = useCommonStore();
//     const solanaNetwork = commonStore.solanaNetwork;
//     const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

//     const connection = new anchor.web3.Connection(clusterUrl);
//     const provider = new anchor.AnchorProvider(connection, wallet)

//     const recentSlot = await provider.connection.getSlot();

//     const [loookupTableInstruction, lookupTableAddress] =
//         AddressLookupTableProgram.createLookupTable({
//             authority: wallet.publicKey,
//             payer: wallet.publicKey,
//             recentSlot,
//         });

//     // add ix on-chain
//     const extendInstruction = AddressLookupTableProgram.extendLookupTable({
//         payer: wallet.publicKey,
//         authority: wallet.publicKey,
//         lookupTable: lookupTableAddress,
//         addresses: [
//             SystemProgram.programId,
//             TOKEN_PROGRAM_ID,
//             new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"), // METADATA_PROGRAM_ID
//             new PublicKey("2JeNLSrJkSaWoFoSQkb1YsxC1dXSaA1LTLjpakzb9SBf"), // owner for payment
//             SYSVAR_INSTRUCTIONS_PUBKEY,
//             TOKEN_AUTH_RULES_ID
//         ],
//     });
//     console.log('create')

//     await createAndSendV0Tx([loookupTableInstruction, extendInstruction]);

// }

// async function createAndSendV0Tx(txInstructions) {

//     const { wallet } = useWorkspace();
//     wallet.publicKey = wallet.publicKey.value ?? wallet.publicKey;
//     wallet.signAllTransactions = wallet.signAllTransactions.value ?? wallet.signAllTransactions;

//     const commonStore = useCommonStore();
//     const solanaNetwork = commonStore.solanaNetwork;
//     const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

//     const connection = new anchor.web3.Connection(clusterUrl);
//     const provider = new anchor.AnchorProvider(connection, wallet)

//     // Step 1 - Fetch Latest Blockhash
//     let latestBlockhash = await connection.getLatestBlockhash('finalized');
//     console.log("✅ - Fetched latest blockhash. Last valid height:", latestBlockhash.lastValidBlockHeight);

//     // Step 2 - Generate Transaction Message
//     const messageV0 = new TransactionMessage({
//         payerKey: wallet.publicKey,
//         recentBlockhash: latestBlockhash.blockhash,
//         instructions: txInstructions
//     }).compileToV0Message();

//     console.log("✅ - Compiled transaction message");
//     const transactionV0 = new VersionedTransaction(messageV0);

//     console.log("execute")
//     await executeTransactions(connection, [transactionV0], provider.wallet.wallet.value)

//     console.log('🎉 Transaction succesfully confirmed!');

// }